<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <v-row>
          <v-col
            cols="12"
            md="7"
            sm="12"
          >
            <v-tabs v-model="tab">
              <v-tab key="dadosCadastrais">
                <span>Dados Cadastrais</span>
              </v-tab>
              <v-tab key="canaisBancarios">
                <span>Canais Bancários</span>
              </v-tab>
              <v-tab key="documentos">
                <span>Documentos</span>
              </v-tab>
              <v-tab key="limites">
                <span>Limites</span>
              </v-tab>
              <v-tab key="spreads">
                <span>Spreads</span>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col
            cols="12"
            md="5" 
            sm="12"
            class="customer-info"
          >
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <span
                  class="externalId"
                  v-if="customer.externalId"
                  v-bind="attrs"
                  v-on="on"
                >
                  <i class="far fa-cloud-upload" />
                </span>
              </template>
              Cliente importado com ID externo {{ customer.externalId }}
            </v-tooltip>
            <div class="d-flex flex-column text-right">
              <span
                class="title-customer-name pb-0"
                cols="12"
              >{{ customer.isPJ() ? customer.companyName : customer.name }}
              </span>
              <span 
                cols="12"
                class="subtitle-customer-cpfcnpj py-0"
              >{{ customer.cpfCnpj }}
              </span>
            </div>
          </v-col>
        </v-row>

        <v-tabs-items v-model="tab">
          <v-tab-item
            eager
            key="dadosCadastrais"
          >
            <v-card>
              <CustomerCover
                :is-p-f="isPF"
                :is-exchange="isExchange"
                :is-basic-remitance="isBasicRemittance"
                :is-complete-remittance="isCompleteRemittance"
                v-model="customer"
              />
              <CustomerContact v-model="customer" />
              <CustomerAddress
                :is-p-f="isPF"
                :is-exchange="isExchange"
                v-model="customer"
              />
              <CustomerWork
                v-if="isCompleteRemittance"
                v-model="customer"
              />
              <CustomerPartnersShareholders v-model="customer" />
              <CustomerDirectorsProxies v-model="customer" />

              <CustomerPoliticallyExposedPerson v-model="customer" />
              <CustomerCompliance
                v-if="isLegalPerson"
                v-model="customer"
              />
              <CustomerRegistrationResponsible v-model="customer" />
            </v-card>
          </v-tab-item>
          <v-tab-item
            eager
            key="canaisBancarios"
          >
            <v-card>
              <CustomerInternationalAccount
                v-if="isRemittance || isLegalPerson"
                v-model="customer"
              />
              <CustomerFinancial
                v-model="customer"
                :is-exchange="isExchange"
                :is-legal-person="isLegalPerson"
                :is-remitance="isRemittance"
                :is-complete-remittance="isCompleteRemittance"
              />
              <CustomerPaymentAccounts v-model="customer" />
            </v-card>
          </v-tab-item>
          <v-tab-item
            eager
            key="documentos"
          >
            <v-card>
              <CustomerDocuments
                :is-legal-person="isLegalPerson"
                :is-exchange="isExchange"
                :is-basic-remitance="isBasicRemittance"
                :is-complete-remittance="isCompleteRemittance"
                v-model="customer"
              />
              <CustomerOperationalDocuments v-model="customer" />
            </v-card>
          </v-tab-item>
          <v-tab-item
            eager
            key="limites"
          >
            <v-card>
              <CustomerExchangeBanks v-model="customer" />
            </v-card>
          </v-tab-item>
          <v-tab-item
            eager
            key="spreads"
          >
            <v-card>
              <CustomerSpreads v-model="customer" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import CustomerCover from "@/components/form-customer/CustomerCover";
import CustomerContact from "@/components/form-customer/CustomerContact";
import CustomerAddress from "@/components/form-customer/CustomerAddress";
import CustomerPartnersShareholders from "@/components/form-customer/CustomerPartnersShareholders.vue";
import CustomerDirectorsProxies from "@/components/form-customer/CustomerDirectorsProxies.vue";
import CustomerFinancial from "@/components/form-customer/CustomerFinancial.vue";
import CustomerWork from "@/components/form-customer/CustomerWork.vue";
import CustomerPoliticallyExposedPerson from "@/components/form-customer/CustomerPoliticallyExposedPerson.vue";
import CustomerCompliance from "@/components/form-customer/CustomerCompliance.vue";
import CustomerRegistrationResponsible from "@/components/form-customer/CustomerRegistrationResponsible.vue";
import CustomerInternationalAccount from "@/components/form-customer/CustomerInternationalAccount.vue";
import CustomerDocuments from "@/components/form-customer/CustomerDocuments.vue";
import CustomerExchangeBanks from "@/components/form-customer/CustomerExchangeBanks.vue";
import CustomerSpreads from "@/components/form-customer/CustomerSpreads.vue";
import CustomerOperationalDocuments from "@/components/form-customer/CustomerOperationalDocuments.vue";
import CustomerPaymentAccounts from "../../components/form-customer/CustomerPaymentAccounts.vue";

export default {
  name: "CustomerFormTab",
  components: {
    CustomerCover,
    CustomerContact,
    CustomerAddress,
    CustomerPartnersShareholders,
    CustomerDirectorsProxies,
    CustomerFinancial,
    CustomerWork,
    CustomerPoliticallyExposedPerson,
    CustomerCompliance,
    CustomerRegistrationResponsible,
    CustomerInternationalAccount,
    CustomerDocuments,
    CustomerOperationalDocuments,
    CustomerExchangeBanks,
    CustomerSpreads,
    CustomerPaymentAccounts,
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
    isLegalPerson: {
      type: Boolean,
    },
    isPF: {
      type: Boolean,
    },
    isExchange: {
      type: Boolean,
    },
    isRemittance: {
      type: Boolean,
    },
    isBasicRemittance: {
      type: Boolean,
    },
    isCompleteRemittance: {
      type: Boolean,
    },

  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  computed: {},
  data() {
    return {
      customer: this.customerProp,
      tab: null,
    };
  },
};
</script>

<style scoped>
.card-cambio {
  position: relative;
}
.externalId {
  z-index: 9;
  border-radius: 8px;
  font-size: 1rem;
  background-color: var(--v-backgroundSecondary-base);
}

.title-customer-name {
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--v-primary-base);
}

.subtitle-customer-cpfcnpj {
  font-weight: 500;
  font-size: 0.9rem;
}

.customer-info {
  display: flex;
  justify-content: end;
  gap: 10px;
}

</style>
