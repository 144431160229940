import CorrespondentModel from './correspondent-model';

export default class OperationAdministrativeModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.pisCofins = obj.pisCofins;
    this.cashbackContract = obj.cashbackContract;
    this.cashbackContractValue = obj.cashbackContractValue;
    this.operationSubTotal = obj.operationSubTotal;
    this.bankSubTotal = obj.bankSubTotal;
    this.grossProfit = obj.grossProfit;
    this.netProfit = obj.netProfit;
    this.comission = obj.comission;
    this.bankComissionValue = obj.bankComissionValue;
    this.cambioHojeComissionValue = obj.cambioHojeComissionValue;
    this.agent = obj.agent;
    this.agentValue = obj.agentValue;
    this.indicator = obj.indicator;
    this.indicatorValue = obj.indicatorValue;
    this.indicatorId = obj.indicatorId;
    this.agentId = obj.agentId;
    this.agentData = new CorrespondentModel(obj.agentData);
    this.indicatorData = new CorrespondentModel(obj.indicatorData);
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}