export default class CustomerDirectorProxyModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.name = obj.name;
    this.cpfCnpj = obj.cpfCnpj;
    this.position = obj.position;
    this.termStartDate = obj.termStartDate;
    this.termEndDate = obj.termEndDate;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}