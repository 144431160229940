import CurrencyModel from './currency-model';

export default class CorrespondentSpreadModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.currency = new CurrencyModel(obj.currency);
    this.minimumSpread = (obj.minimumSpread && parseFloat(obj.minimumSpread)) || 0;
    this.maximumSpread = (obj.maximumSpread && parseFloat(obj.maximumSpread)) || 0;
    this.recommendedSpread = (obj.recommendedSpread && parseFloat(obj.recommendedSpread)) || 0;
    this.minimumSpreadBuy = (obj.minimumSpreadBuy && parseFloat(obj.minimumSpreadBuy)) || 0;
    this.maximumSpreadBuy = (obj.maximumSpreadBuy && parseFloat(obj.maximumSpreadBuy)) || 0;
    this.recommendedSpreadBuy = (obj.recommendedSpreadBuy && parseFloat(obj.recommendedSpreadBuy)) || 0;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}