import CurrencyModel from './currency-model';

export default class SpreadModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.currency = new CurrencyModel(obj.currency);
    this.cost = (obj.cost && parseFloat(obj.cost)) || 0;
    this.minimumProfit = (obj.minimumProfit && parseFloat(obj.minimumProfit)) || 0;
    this.maximumProfit = (obj.maximumProfit && parseFloat(obj.maximumProfit)) || 0;
    this.recommendedProfit = (obj.recommendedProfit && parseFloat(obj.recommendedProfit)) || 0;
    this.deliveryCostExemptionAmount = obj.deliveryCostExemptionAmount;
    this.minimumSpread = obj.minimumSpread || 0;
    this.maximumSpread = obj.maximumSpread || 0;
    this.recommendedSpread = obj.recommendedSpread || 0;
    this.costBuy = (obj.costBuy && parseFloat(obj.costBuy)) || 0;
    this.minimumProfitBuy = (obj.minimumProfitBuy && parseFloat(obj.minimumProfitBuy)) || 0;
    this.maximumProfitBuy = (obj.maximumProfitBuy && parseFloat(obj.maximumProfitBuy)) || 0;
    this.recommendedProfitBuy = (obj.recommendedProfitBuy && parseFloat(obj.recommendedProfitBuy)) || 0;
    this.minimumSpreadBuy = obj.minimumSpreadBuy || 0;
    this.maximumSpreadBuy = obj.maximumSpreadBuy || 0;
    this.recommendedSpreadBuy = obj.recommendedSpreadBuy || 0;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}