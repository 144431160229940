<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1>Endereço</h1>
        <v-divider class="py-3" />
        <PFCustomerAddress
          v-if="isPF"
          :is-exchange="isExchange"
          v-model="customer"
        />
        <PJCustomerAddress
          v-else
          v-model="customer"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import PFCustomerAddress from "./PF/address/PFCustomerAddress.vue";
import PJCustomerAddress from "./PJ/address/PJCustomerAddress.vue";

// Constants
import { REGISTER_TYPE } from "@/constants/general-constants.js";

export default {
  name: "CustomerAddress",
  components: { PFCustomerAddress, PJCustomerAddress },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
    isPF: {
      type: Boolean,
    },
    isExchange: {
      type: Boolean,
    }
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  computed: {
    
  },
  data() {
    return {
      customer: this.customerProp,
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
    };
  },
};
</script>

<style>
</style>