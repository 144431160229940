const OPERATION_WORKFLOW = {
  EM_ANALISE: {
    id: 1,
    description: 'Em análise',
    next: () => {
      return [OPERATION_WORKFLOW.CANCELADA, OPERATION_WORKFLOW.COM_PENDENCIAS, OPERATION_WORKFLOW.EM_LIQUIDACAO, OPERATION_WORKFLOW.LIQUIDADA];
    },
  },
  COM_PENDENCIAS: {
    id: 2,
    description: 'Com pendências',
    next: () => {
      return [OPERATION_WORKFLOW.CANCELADA, OPERATION_WORKFLOW.ANALISE_PENDENCIAS];
    },
  },
  ANALISE_PENDENCIAS: {
    id: 3,
    description: 'Análise de pendências',
    next: () => {
      return [OPERATION_WORKFLOW.CANCELADA, OPERATION_WORKFLOW.COM_PENDENCIAS, OPERATION_WORKFLOW.EM_LIQUIDACAO, OPERATION_WORKFLOW.LIQUIDADA];
    },
  },
  EM_LIQUIDACAO: {
    id: 4,
    description: 'Em Liquidação',
    next: () => {
      return [OPERATION_WORKFLOW.CANCELADA, OPERATION_WORKFLOW.LIQUIDADA];
    },
  },
  LIQUIDADA: {
    id: 5,
    description: 'Liquidada',
    next: () => {
      return [OPERATION_WORKFLOW.CANCELADA];
    },
  },
  CANCELADA: {
    id: 6,
    description: 'Cancelada',
    next: () => {
      return [];
    },
  }
}

const OPERATION_WORKFLOW_LIST = Object.values(OPERATION_WORKFLOW);

export {
  OPERATION_WORKFLOW,
  OPERATION_WORKFLOW_LIST,
}