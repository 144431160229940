import ExchangeBankTypeModel from './exchange-bank-type-model';

export default class CorrespondentAdministrativeModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.exchangeType = new ExchangeBankTypeModel(obj.exchangeType);
    this.commission = obj.commission;
    this.tourismCommission = obj.tourismCommission;
    this.globalMinimumSpread = obj.globalMinimumSpread;
    this.globalMaximumSpread = obj.globalMaximumSpread;
    this.globalRecommendedSpread = obj.globalRecommendedSpread;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}