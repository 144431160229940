export default class ConfigurationModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.session = obj.session;
    this.group = obj.group;
    this.name = obj.name;
    this.title = obj.title;
    this.value = obj.value;
    this.observation = obj.observation;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}