var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(
      this.$router.currentRoute.name != 'Login' &&
      this.$router.currentRoute.name != 'FormEditCustomerExternalAcess' &&
      this.$router.currentRoute.name != 'FormEditCorrespondentExternalAcess' &&
      this.$router.currentRoute.name != 'FileView' &&
      this.$router.currentRoute.name != 'FormCustomerFinally' &&
      this.$router.currentRoute.name != 'CustomerDocumentsExternalAccess' &&
      this.$router.currentRoute.name != 'OperationDocumentsExternalAccess'
    )?_c('Menu',{key:_vm.key,model:{value:(_vm.isOpenSimulator),callback:function ($$v) {_vm.isOpenSimulator=$$v},expression:"isOpenSimulator"}}):_vm._e(),_c('v-snackbar',{attrs:{"vertical":false,"timeout":_vm.timeout,"top":"top","color":_vm.type,"elevation":"0","right":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" OK ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),(
      this.$router.currentRoute.meta.requireAuth &&
      _vm.isOpenSimulator &&
      this.$router.currentRoute.name != 'FileView'
    )?_c('OperationSimulator',{model:{value:(_vm.isOpenSimulator),callback:function ($$v) {_vm.isOpenSimulator=$$v},expression:"isOpenSimulator"}}):_vm._e(),_c('v-main',[_c('router-view'),_c('v-dialog',{staticClass:"pa-5",attrs:{"persistent":"","width":"200","height":"200"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{staticClass:"pa-5"},[_c('v-card-text',{staticClass:"d-flex justify-center align-center text-center flex-column"},[_c('v-progress-circular',{staticClass:"m-0",attrs:{"size":80,"indeterminate":"","color":"primary"}}),_c('h2',{staticClass:"pt-2"},[_vm._v("Carregando")])],1)],1)],1),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"by"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" - Powered by DeepRocket Tecnologia LTDA ")]),_c('div',{attrs:{"by":"version"}},[_vm._v(_vm._s(_vm.version))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }