import apiBanks from "../api/bank-api";
let listOfBanks = [];

async function _setBankList() {
  if (listOfBanks.length) {
    return;
  }

  let response = {};

  try {
    response = await apiBanks.getBanks();
  } catch (error) {
    return;
  }
  let banks = response.data

  listOfBanks = banks.reduce((accumulator, current) => {
    if (current.name == undefined || !current.code) {
      return accumulator;
    }
    accumulator.push(current);
    return accumulator;
  }, []);
}

_setBankList();

async function getBankList() {
  await _setBankList();
  return listOfBanks;
}

function getBankByCode(code) {
  return listOfBanks.find(i => i.code == code);
}

export default {
  getBankList,
  getBankByCode,
}