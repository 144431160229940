import CustomerModel from './../customer-model';
import ExchangeBankModel from './../exchange-bank-model';
import CorrespondentModel from './../correspondent-model';

export default class EmailLogModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.subject = obj.subject;
    this.content = obj.content;
    this.emails = obj.emails;
    this.customer = new CustomerModel(obj.customer);
    this.bank = new ExchangeBankModel(obj.bank);
    this.corban = new CorrespondentModel(obj.corban);
    this.createdAt = obj.createdAt;
  }
}
