<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <v-row class="pt-4">
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="(Opcional) Cliente / CPF / CNPJ "
              :items="customerList"
              v-model="operation.customer"
              item-value="id"
              :item-text="itemTextCustomers"
              return-object
              dense
              clearable
              autofocus
              outlined
              v-disabled-icon-focus
              :filter="caseInsensitiveAccentsInsensitiveIgnoreDotsDashes"
              :loading="customerSearchLoading"
              :search-input.sync="customerSearchQuery"
              hide-no-data
              cache-items
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="Cidade"
              :items="cityList"
              v-model="operation.storeCity"
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteText]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="Banco"
              :items="bankList"
              v-model="operation.bank"
              item-value="id"
              item-text="companyName"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="Loja"
              :items="storeList"
              v-model="operation.store"
              item-value="id"
              :item-text="itemTextStores"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="Natureza"
              :items="natures"
              v-model="operation.nature"
              item-value="id"
              item-text="exibitionName"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="Moeda"
              :items="currencyList"
              v-model="operation.currency"
              item-value="id"
              :item-text="itemTextCurrencies"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
            v-if="calculationTypeConst.CURRENCY_AMOUNT.id == calculationType"
          >
            <MoneyField
              label="Quantidade ME"
              prefix=" "
              v-model="operation.amount"
              :rules="[validationIsRequiredFieldNumeric]"
              validate-on-blur
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
            v-else
          >
            <MoneyField
              label="Quantidade Reais"
              prefix=" "
              v-model="operation.totalValue"
              :rules="[validationIsRequiredFieldNumeric]"
              validate-on-blur
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="Forma de Entrega"
              :items="deliveryTypeList"
              v-model="operation.deliveryType"
              item-value="id"
              item-text="description"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            v-if="operation.deliveryType?.id == deliveryTypeConst.DELIVERY.id"
            cols="12"
            md="3"
            class="py-0"
          >
            <MoneyField
              label="Custo de Entrega"
              prefix=" "
              v-model="operation.deliveryCost"
              :rules="[validationIsRequiredFieldNumericAllowZero]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <MoneyField
              label="Spread"
              prefix=" "
              suffix=" %"
              v-model="operation.spread"
              persistent-hint
              :hint="spreadHint"
              :min="operation.spreadMin"
              :max="operation.spreadMax"
              :rules="[validationIsRequiredFieldNumeric]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <MoneyField
              label="Taxa do Cliente"
              prefix="R$ "
              suffix=""
              :precision="5"
              v-model="operation.customerRate"
              persistent-hint
              :hint="customerRateHint"
              :min="operation.customerRateMin"
              :max="operation.customerRateMax"
              :rules="[validationIsRequiredFieldNumeric]"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="12"
      align="right"
      class="pb-2"
    >
      <v-card class="box card-cambio">
        <v-row class="pa-5">
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <h1 class="operation-title">Câmbio comercial (SPOT)</h1>
            <span class="operation-value">{{ operation.currencyQuotation || 0 | formatCurrencyBrl(5) }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <h1 class="operation-title">IOF({{ operation.iof || 0 }}%)</h1>
            <span class="operation-value">{{ operation.iofValue || 0 | formatCurrencyBrl(2) }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <h1 class="operation-title">IR({{ operation.ir || 0 }}%)</h1>
            <span class="operation-value">{{ operation.irValue || 0 | formatCurrencyBrl(2) }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
            v-if="calculationTypeConst.CURRENCY_AMOUNT.id == calculationType"
          >
            <h1 class="operation-title">Total</h1>
            <span class="operation-value">{{ operation.totalValue || 0 | formatCurrencyBrl(2) }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
            v-else
          >
            <h1 class="operation-title">Total</h1>
            <span class="operation-value">{{ operation.currency.code }} {{ operation.amount || 0 | formatNumber(2)
            }}</span>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import MoneyField from "@/components/comum/MoneyField";

// Api
import apiExchangeStore from "@/api/exchangeStore/exchange-store-api";
import apiExchangeBank from "@/api/exchangeBank/exchange-bank-api";
import apiOperationNature from "@/api/configuration/operation-nature-api";
import apiDeliveryType from "@/api/exchangeStore/delivery-type-api";
import apiCustomers from "@/api/customer/customer-api";

// Models
import ExchangeStoreModel from "@/model/exchange-store-model";
import ExchangeBankModel from "@/model/exchange-bank-model";
import OperationNatureModel from "@/model/operation-nature-model";
import DeliveryTypeModel from "@/model/delivery-type-model";
import CustomerModel from "@/model/customer-model";
import CustomerExchangeBankModel from "@/model/customer-exchange-bank-model";
import ExchangeBankFiltersModel from "@/model/exchangeBank/exchange-bank-filters-model";
import CustomerFiltersModel from "@/model/customer/customer-filters-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinValidationRules from "@/mixin/mixin-validation-rules";
import mixinAutoCompleteFilters from "@/mixin/mixin-autocomplete-filters";

// Constants
import { OPERATION_TYPE, DELIVERY_TYPE, OPERATION_CALCULATION_TYPE } from "@/constants/general-constants";
import { CUSTOMER_WORKFLOW } from "../../../../common/workflows/customersWorkflow";

// Utils
import numberUtils from "../../../../common/utils/number";

export default {
  name: "SimulatorExchange",
  mixins: [mixinMessage, mixinValidationRules, mixinAutoCompleteFilters],
  components: {
    MoneyField,
  },
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
    },
    calculationType: {
      type: [Number, String],
    },
  },
  data() {
    return {
      operation: this.operationProp,
      cities: [],
      banks: [],
      stores: [],
      natures: [],
      deliveryTypes: [],
      deliveryTypeConst: DELIVERY_TYPE,
      calculationTypeConst: OPERATION_CALCULATION_TYPE,
      customerList: [],
      exchangeBankList: [],
      customerSearchLoading: false,
      customerSearchQuery: '',
    };
  },
  mounted() {
    this.getCities();
    this.getNatures();
    this.getDeliveryTypes();
  },
  filters: {
    formatCurrencyBrl(value, precison) {
      return numberUtils.formatCurrency('BRL', value, precison);
    },
    formatNumber(value) {
      return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value);
    },
  },
  watch: {
    operation() {
      this.emitOperation();
    },
    "operation.storeCity"() {
      if (!this.operation.storeCity) {
        this.banks = [];

        return;
      }

      this.getBanks();
    },
    "operation.bank"() {
      if (!this.operation.bank?.id) {
        this.stores = [];

        return;
      }

      this.getStores();
    },
    "deliveryTypeList"() {
      if (this.deliveryTypeList.length == 1) {
        this.operation.deliveryType = this.deliveryTypeList[0];
      }
    },
    "operation.amount"() {
      this.getDeliveryCost();

    },
    "operation.deliveryType"() {
      this.getDeliveryCost();
    },

    "operation.customer"() {
      if (this.operation.customer == undefined) {
        this.exchangeBankList = [];
        this.operation.customer = new CustomerModel();

        return;
      }

      this.getCustomerExchangeBanks();
    },
    customerSearchQuery() {
      clearTimeout(this.customerSearchTimer);

      if (this.customerSearchQuery == this.itemTextCustomers(this.operation.customer)) {
        return;
      }

      if ((this.customerSearchQuery?.length || 0) < 3) {
        this.customerList = [];

        return;
      }


      this.customerSearchTimer = setTimeout(() => {
        this.customerSearchLoading = true;
        this.getCustomersList()
          .then(() => { 
            this.customerSearchLoading = false;
          });
      }, 500);
    }
  },
  computed: {
    bankList() {
      if (this.exchangeBankList.length) {
        return this.exchangeBankList.filter(i => i.bank.exchangeStores.filter(j => j.city == this.operation.storeCity).length).map(k => k.bank) || [];
      }

      return this.banks.filter(i => i.exchangeType.id == OPERATION_TYPE.EXCHANGE.id || i.exchangeType.id == OPERATION_TYPE.BOTH.id) || [];
    },
    itemTextStores() {
      return (item) => item.name + ' - ' + item.cnpj;
    },
    currencyList() {
      return this.operation.store?.exchangeSpreads.map(k => k.currency) || [];
    },
    itemTextCurrencies() {
      return (item) => item.code + ' - ' + item.name;
    },
    deliveryTypeList() {
      let type = this.operation.store?.deliveryType || {};

      if (!type.id) {
        return [];
      }

      if (type.id == DELIVERY_TYPE.BOTH.id) {
        return this.deliveryTypes.filter(i => i.id != DELIVERY_TYPE.BOTH.id);
      }

      return [type];
    },
    spreadHint() {
      if (this.operation.spreadMin && this.operation.spreadMax) {
        return `Min: ${this.operation.spreadMin}% - Max: ${this.operation.spreadMax}%`;
      }

      return '';
    },
    customerRateHint() {
      if (this.operation.customerRateMin && this.operation.customerRateMax) {
        return `Min: ${this.formatBRL(this.operation.customerRateMin, 6)} - Max: ${this.formatBRL(this.operation.customerRateMax, 6)}`;
      }

      return '';
    },
    itemTextCustomers() {
      return (item) => !item.name ? item.companyName + ' - ' + item.cpfCnpj : item.name + ' - ' + item.cpfCnpj;
    },
    cityList() {
      if (this.exchangeBankList.length) {
        let flattened = this.exchangeBankList.map(i => i.bank.exchangeStores.map(j => j.city)).flat();

        return Array.from(new Set(flattened));
      }

      return this.cities;
    },
    storeList() {
      if (this.exchangeBankList.length) {
        return this.operation?.bank.exchangeStores.filter(k => k?.city == this.operation.storeCity) || [];
      }

      return this.stores;
    },
  },
  methods: {
    getCities() {
      if (this.cities[0]) {
        return;
      }

      apiExchangeStore.findCities()
        .then((response) => {
          this.cities = response.data;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getBanks() {
      let filter = new ExchangeBankFiltersModel({ storeCity: this.operation.storeCity });
      apiExchangeBank
        .findAll(filter)
        .then((response) => {
          this.banks = response.data.map((b) => new ExchangeBankModel(b));

          if (this.bankList.length == 1) {
            this.operation.bank = this.bankList[0];
          }
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getStores() {
      apiExchangeStore
        .findByBank(this.operation.bank.id)
        .then((response) => {
          this.stores = response.data.map((s) => new ExchangeStoreModel(s));

          if (this.stores.length == 1) {
            this.operation.store = this.stores[0];
          }
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getNatures() {
      if (this.natures[0]) {
        return;
      }

      apiOperationNature
        .findAll()
        .then((response) => {
          this.natures = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getDeliveryTypes() {
      if (this.deliveryTypes[0]) {
        return;
      }

      apiDeliveryType
        .findAll()
        .then((response) => {
          this.deliveryTypes = response.data.map(
            (t) => new DeliveryTypeModel(t)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getDeliveryCost() {
      if (!this.operation.store?.id) {
        return;
      }

      let spread = this.operation.store.exchangeSpreads.find(s => s.currency.id == this.operation.currency.id);

      if (this.operation.deliveryType.id == DELIVERY_TYPE.DELIVERY.id && this.operation.amount < spread.deliveryCostExemptionAmount) {
        this.operation.deliveryCost = this.operation.store.deliveryCost;
      } else {
        this.operation.deliveryCost = 0;
      }
    },

    formatBRL(value, precision) {
      return numberUtils.formatCurrency('BRL', value, precision);
    },

    emitOperation() {
      this.$emit("onChange", this.operation);
    },

    getCustomersList() {
      let filters = new CustomerFiltersModel({
        registerStatusList: [CUSTOMER_WORKFLOW.CADASTRO_APROVADO.id],
        searchText: this.customerSearchQuery,
      });

      return apiCustomers
        .findAll(filters)
        .then((response) => {
          this.customerList = response.data.map((c) => new CustomerModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getCustomerExchangeBanks() {
      if (!this.operation.customer?.id) {
        return;
      }

      apiCustomers
        .findExchangeBanks(this.operation.customer.id, this.operation.exchangeType.id)
        .then((response) => {
          this.exchangeBankList = response.data.map((c) => new CustomerExchangeBankModel(c)).filter(i => i.limit.id);
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.operation-title {
  text-align: left;
  font-size: 0.9rem;
}

.operation-value {
  font-size: 1.3rem;
  text-align: right;
  color: var(--v-primary-base);
}
</style>
