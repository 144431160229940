const mixinDataTableFilters = {
  data() {
    return {

    };
  },
  methods: {
    caseInsensitiveAccentsInsensitive(value, search) {
      if (!value || !search) {
        return;
      }

      let normalizedValue = value.toString().normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase();
      let normalizedSearch = search.toString().normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase();

      return normalizedValue.indexOf(normalizedSearch) != -1;
    },
  }
};

export default mixinDataTableFilters;