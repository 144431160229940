export default class TriggerModel {
  constructor(obj) {
    obj = obj || {};
    this.code = obj.code;
    this.idCustomer = obj.idCustomer;
    this.idCorban = obj.idCorban;
    this.idBank = obj.idBank;
    this.idOperation = obj.idOperation;
    this.documentIds = obj.documentIds;
    this.customerOperationalDocumentIds  = obj.customerOperationalDocumentIds;
    this.customerComplementaryInfo  = obj.customerComplementaryInfo;
    this.bankComplementaryInfo = obj.bankComplementaryInfo;
  }
}