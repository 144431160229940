import api from '../api';



function findAll() {
  return new Promise((resolve, reject) => {
    return api.get('/configurations')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findById(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.get(`/configurations/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findBySession(session) {
  return new Promise((resolve, reject) => {
    return api.get(`/configurations/session/${session}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findByGroup(group) {
  return new Promise((resolve, reject) => {
    return api.get(`/configurations/group/${group}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function updateList(configurations) {
  configurations = configurations || [];

  return new Promise((resolve, reject) => {
    return api.put(`/configurations/list`, configurations)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  findById,
  findBySession,
  findByGroup,
  updateList
};