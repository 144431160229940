import api from '../api';

function findAll() {
  return new Promise((resolve, reject) => {
    return api.get(`/exchange-stores/delivery-types`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}


export default {
  findAll,
};