'use strict';

import dateUtils from '../../../common/utils/date';

function calculateOperationRemittance(operation) {
  if (operation.exchangeBuy) {
    _calculateOperationRemittanceBuy(operation);
  } else {
    _calculateOperationRemittanceSell(operation);
  }
}

function _calculateOperationRemittanceBuy(operation) {
  operation.iofValue = ((operation.customerRate * operation.amount * operation.iof / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.irValue = ((operation.customerRate * operation.amount * operation.ir / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.cideValue = ((operation.customerRate * operation.amount * operation.cide / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.exchangeContractCost = (operation.exchangeContractCost == 0) ? operation.exchangeContractCost : (operation.exchangeContractCost || operation.bank.exchangeContractCost || 0);
  operation.totalValue = ((parseFloat(operation.customerRate) * parseFloat(operation.amount) - parseFloat(operation.irValue) - parseFloat(operation.iofValue) - parseFloat(operation.cideValue) - parseFloat(operation.exchangeContractCostBrl)) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.vet = ((operation.totalValue / operation.amount) || 0).toFixed(4);
}

function _calculateOperationRemittanceSell(operation) {
  operation.iofValue = ((operation.customerRate * operation.amount * operation.iof / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.irValue = ((operation.customerRate * operation.amount * operation.ir / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.cideValue = ((operation.customerRate * operation.amount * operation.cide / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.exchangeContractCost = (operation.exchangeContractCost == 0) ? operation.exchangeContractCost : (operation.exchangeContractCost || operation.bank.exchangeContractCost || 0);
  operation.totalValue = ((parseFloat(operation.customerRate) * parseFloat(operation.amount) + parseFloat(operation.irValue) + parseFloat(operation.iofValue) + parseFloat(operation.cideValue) + parseFloat(operation.exchangeContractCostBrl)) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.vet = ((operation.totalValue / operation.amount || 0)).toFixed(4);
}

function setBaseValuesOperationRemittance(operation) {
  if (operation.exchangeBuy) {
    _setBaseValuesOperationRemittanceBuy(operation);
  } else {
    _setBaseValuesOperationRemittanceSell(operation);
  }
}

function _setBaseValuesOperationRemittanceBuy(operation) {
  let spreadAgent = operation.agent.spreads.find(i => i.currency?.id == operation.currency?.id);
  let spreadCustomer = operation.customer.spreads.find(i => i.currency?.id == operation.currency?.id);

  operation.iof = operation.nature.iofBuy || 0;
  operation.ir = operation.nature.irBuy || 0;
  operation.cide = +(operation.nature.cideBuy || 0);
  operation.spread = operation.spread || spreadCustomer?.recommendedSpreadBuy || spreadAgent?.recommendedSpreadBuy || operation.currency?.recommendedSpreadBuy || 0;
  operation.spreadMin = operation.spreadMin || spreadCustomer?.minimumSpreadBuy || spreadAgent?.minimumSpreadBuy || operation.currency?.minimumSpreadBuy || 0;
  operation.spreadMax = operation.spreadMax || spreadCustomer?.maximumSpreadBuy || spreadAgent?.maximumSpreadBuy || operation.currency?.maximumSpreadBuy || 0;
  operation.customerRate = ((operation.currencyQuotation * (1 - operation.spread / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.customerRateMin = ((operation.currencyQuotation * (1 - operation.spreadMax / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.customerRateMax = ((operation.currencyQuotation * (1 - operation.spreadMin / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
}

function _setBaseValuesOperationRemittanceSell(operation) {
  let spreadAgent = operation.agent.spreads.find(i => i.currency?.id == operation.currency?.id);
  let spreadCustomer = operation.customer.spreads.find(i => i.currency?.id == operation.currency?.id);

  if (operation.nature.bilateralAgreementCountries.length) {
    let index = operation.nature.bilateralAgreementCountries.findIndex(i => i.country == operation.country);
    index == -1 ? (operation.ir = operation.nature.ir || 0) : (operation.ir = operation.nature.bilateralAgreementCountries[index].ir || 0);
  } else {
    operation.ir = operation.nature.ir || 0;
  }

  operation.iof = operation.nature.iof || 0;
  operation.cide = +(operation.nature.cide || 0);
  operation.spread = operation.spread || spreadCustomer?.recommendedSpreadSell || spreadAgent?.recommendedSpread || operation.currency?.recommendedSpreadSell || 0;
  operation.spreadMin = operation.spreadMin || spreadCustomer?.minimumSpreadSell || spreadAgent?.minimumSpread || operation.currency?.minimumSpreadSell || 0;
  operation.spreadMax = operation.spreadMax || spreadCustomer?.maximumSpreadSell || spreadAgent?.maximumSpread || operation.currency?.maximumSpreadSell || 0;
  operation.customerRate = ((operation.currencyQuotation * (1 + operation.spread / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.customerRateMin = ((operation.currencyQuotation * (1 + operation.spreadMin / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.customerRateMax = ((operation.currencyQuotation * (1 + operation.spreadMax / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
}

function resetSpreads(operation) {
  operation.spreadMin = undefined;
  operation.spreadMax = undefined;
  operation.spread = undefined;
}

function getSpreadFromCustomerRateRemittance(operation) {
  if (operation.exchangeBuy) {
    _getSpreadFromCustomerRateRemittanceBuy(operation);
  } else {
    _getSpreadFromCustomerRateRemittanceSell(operation);
  }

  if (operation.spread < operation.spreadMin) {
    operation.spread = operation.spreadMin;
    setBaseValuesOperationRemittance(operation);

    return;
  }

  if (operation.spread > operation.spreadMax) {
    operation.spread = operation.spreadMax;
    setBaseValuesOperationRemittance(operation);
  }
}

function _getSpreadFromCustomerRateRemittanceBuy(operation) {
  operation.spread = ((- (operation.customerRate / operation.currencyQuotation) + 1) * 100);
}

function _getSpreadFromCustomerRateRemittanceSell(operation) {
  operation.spread = (((operation.customerRate / operation.currencyQuotation) - 1) * 100);
}

function setBaseValuesOperationExchange(operation) {
  if (operation.exchangeBuy) {
    _setBaseValuesOperationExchangeBuy(operation);
  } else {
    _setBaseValuesOperationExchangeSell(operation);
  }
}

function _setBaseValuesOperationExchangeBuy(operation) {
  let storeSpread = operation.store.exchangeSpreads.find(i => i.currency?.id == operation.currency?.id);

  operation.iof = operation.nature.iofBuy || 0;
  operation.cide = +(operation.nature.cideBuy || 0);

  operation.spread = operation.spread || storeSpread?.recommendedSpreadBuy;
  operation.spreadMin = operation.spreadMin || storeSpread?.minimumSpreadBuy;
  operation.spreadMax = operation.spreadMax || storeSpread?.maximumSpreadBuy;

  operation.customerRateNoIOF = ((operation.currencyQuotation * (1 - operation.spread / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.customerRateNoIOFMin = ((operation.currencyQuotation * (1 - operation.spreadMax / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.customerRateNoIOFMax = ((operation.currencyQuotation * (1 - operation.spreadMin / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);

  operation.customerRate = ((operation.customerRateNoIOF * (1 - operation.iof / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.customerRateMin = ((operation.customerRateNoIOFMin * (1 - operation.iof / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.customerRateMax = ((operation.customerRateNoIOFMax * (1 - operation.iof / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);

}

function _setBaseValuesOperationExchangeSell(operation) {
  let storeSpread = operation.store.exchangeSpreads.find(i => i.currency?.id == operation.currency?.id);

  operation.iof = operation.nature.iof || 0;
  operation.cide = +(operation.nature.cide || 0);

  operation.spread = operation.spread || storeSpread?.recommendedSpread;
  operation.spreadMin = operation.spreadMin || storeSpread?.minimumSpread;
  operation.spreadMax = operation.spreadMax || storeSpread?.maximumSpread;

  operation.customerRateNoIOF = ((operation.currencyQuotation * (1 + operation.spread / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.customerRateNoIOFMin = ((operation.currencyQuotation * (1 + operation.spreadMin / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.customerRateNoIOFMax = ((operation.currencyQuotation * (1 + operation.spreadMax / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);

  operation.customerRate = ((operation.customerRateNoIOF * (1 + operation.iof / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.customerRateMin = ((operation.customerRateNoIOFMin * (1 + operation.iof / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.customerRateMax = ((operation.customerRateNoIOFMax * (1 + operation.iof / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
}

function calculateOperationExchange(operation) {
  if (operation.exchangeBuy) {
    _calculateOperationExchangeBuy(operation);
  } else {
    _calculateOperationExchangeSell(operation);
  }
}

function _calculateOperationExchangeBuy(operation) {
  operation.cideValue = ((operation.customerRate * operation.amount * operation.cide / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.totalValue = ((operation.customerRate * operation.amount - parseFloat(operation.deliveryCost) - parseFloat(operation.cideValue)) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.vet = ((operation.totalValue / operation.amount) || 0).toFixed(4);
  operation.iofValue = ((operation.customerRate * operation.amount * operation.iof / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
}

function _calculateOperationExchangeSell(operation) {
  operation.cideValue = ((operation.customerRate * operation.amount * operation.cide / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.totalValue = ((operation.customerRate * operation.amount + parseFloat(operation.deliveryCost) + parseFloat(operation.cide)) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.vet = ((operation.totalValue / operation.amount) || 0).toFixed(4);
  operation.iofValue = ((operation.customerRate * operation.amount * operation.iof / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
}

function getSpreadFromCustomerRateExchange(operation) {
  if (operation.exchangeBuy) {
    _getSpreadFromCustomerRateExchangeBuy(operation);
  } else {
    _getSpreadFromCustomerRateExchangeSell(operation);
  }

  if (operation.spread < operation.spreadMin) {
    operation.spread = operation.spreadMin;
    setBaseValuesOperationExchange(operation);

    return;
  }

  if (operation.spread > operation.spreadMax) {
    operation.spread = operation.spreadMax;
    setBaseValuesOperationExchange(operation);
  }
}

function _getSpreadFromCustomerRateExchangeBuy(operation) {
  operation.customerRateNoIOF = ((operation.customerRate / (1 - operation.iof / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.spread = ((- (operation.customerRateNoIOF / operation.currencyQuotation) + 1) * 100);
}

function _getSpreadFromCustomerRateExchangeSell(operation) {
  operation.customerRateNoIOF = ((operation.customerRate / (1 + operation.iof / 100)) || 0).toFixed(BIG_DECIMALS_PLACES);
  operation.spread = (((operation.customerRateNoIOF / operation.currencyQuotation) - 1) * 100);
}

function validationOperationLimitNotExpired(operation, exchangeBankList) {
  let limit = exchangeBankList.filter(i => i.bank?.id == operation.bank?.id).map(j => j.limit).pop();

  if (dateUtils.endDateBiggerOrEqualStartDate(limit?.expireAt, new Date().toISOString())) {
    return "O limite deste banco expirou em " + dateUtils.maskDateIso(limit?.expireAt) + ".";
  }

  let remainingLimit = 0;

  if (limit.isInOut) {
    remainingLimit = (operation.exchangeBuy ? limit.remainingIn : limit.remainingOut) || 0;
  } else {
    remainingLimit = limit?.remaining || 0;
  }

  if (remainingLimit <= 0) {
    return "Este banco não possui limite disponível para esta operação.";
  }

  return true;
}

function validationOperationHasLimit(operation, exchangeBankList) {
  let limit = exchangeBankList.filter(i => i.bank?.id == operation.bank?.id).map(j => j.limit).pop();

  if (dateUtils.endDateBiggerOrEqualStartDate(limit?.expireAt, new Date().toISOString())) {
    return "O limite deste banco expirou em " + dateUtils.maskDateIso(limit?.expireAt) + ".";
  }

  let remainingLimit = 0;

  if (limit.isInOut) {
    remainingLimit = (operation.exchangeBuy ? limit.remainingIn : limit.remainingOut) || 0;
  } else {
    remainingLimit = limit?.remaining || 0;
  }

  if ((remainingLimit - operation.reducedLimit) <= 0) {
    return "A operação excede o limite disponível para este banco.";
  }

  return true;
}

const REFRESH_RATE_TIME = 10000; /* 10 seconds */


function calculateOperationExchangeFromBrl(operation) {
  if (!(+operation.totalValue)) {
    return;
  }

  if (operation.exchangeBuy) {
    _calculateOperationExchangeBuyFromBrl(operation);
  } else {
    _calculateOperationExchangeSellFromBrl(operation);
  }
}

function _calculateOperationExchangeBuyFromBrl(operation) {
  let valueNoDelivery = operation.totalValue + parseFloat(operation.deliveryCost);
  let valueNoDeliveryNoCide = valueNoDelivery / (1 - operation.cide / 100);

  operation.amount = (valueNoDeliveryNoCide / operation.customerRate).toFixed(SHORT_DECIMALS_PLACES);
  operation.cideValue = ((valueNoDeliveryNoCide - valueNoDelivery) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.vet = ((operation.totalValue / operation.amount) || 0).toFixed(4);
  operation.iofValue = ((valueNoDeliveryNoCide * operation.iof / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
}

function _calculateOperationExchangeSellFromBrl(operation) {
  let valueNoDelivery = operation.totalValue - parseFloat(operation.deliveryCost);
  let valueNoDeliveryNoCide = valueNoDelivery / (1 + operation.cide / 100);

  operation.amount = (valueNoDeliveryNoCide / operation.customerRate).toFixed(SHORT_DECIMALS_PLACES);
  operation.cideValue = ((valueNoDelivery - valueNoDeliveryNoCide) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.vet = ((operation.totalValue / operation.amount) || 0).toFixed(4);
  operation.iofValue = ((valueNoDeliveryNoCide * operation.iof / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
}

function calculateOperationRemittanceFromBrl(operation) {
  if (!(+operation.totalValue)) {
    return;
  }

  if (operation.exchangeBuy) {
    _calculateOperationRemittanceBuyFromBrl(operation);
  } else {
    _calculateOperationRemittanceSellFromBrl(operation);
  }
}

function _calculateOperationRemittanceBuyFromBrl(operation) {
  operation.exchangeContractCost = (operation.exchangeContractCost == 0) ? operation.exchangeContractCost : (operation.exchangeContractCost || operation.bank.exchangeContractCost || 0);

  let valueNoContract = operation.totalValue + parseFloat(operation.exchangeContractCost);
  let valueNoContractNoTaxes = valueNoContract / (1 - (operation.iof / 100) - (operation.ir / 100) - (operation.cide / 100) );

  operation.iofValue = ((valueNoContractNoTaxes * operation.iof / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.irValue = ((valueNoContractNoTaxes * operation.ir / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.cideValue = ((valueNoContractNoTaxes * operation.cide / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.amount = (valueNoContractNoTaxes / operation.customerRate).toFixed(SHORT_DECIMALS_PLACES);
  operation.vet = ((operation.totalValue / operation.amount) || 0).toFixed(4);
}

function _calculateOperationRemittanceSellFromBrl(operation) {
  operation.exchangeContractCost = (operation.exchangeContractCost == 0) ? operation.exchangeContractCost : (operation.exchangeContractCost || operation.bank.exchangeContractCost || 0);

  let valueNoContract = operation.totalValue - parseFloat(operation.exchangeContractCost);
  let valueNoContractNoTaxes = valueNoContract / (1 + (operation.iof / 100) + (operation.ir / 100) + (operation.cide / 100));

  operation.iofValue = ((valueNoContractNoTaxes * operation.iof / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.irValue = ((valueNoContractNoTaxes * operation.ir / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.cideValue = ((valueNoContractNoTaxes * operation.cide / 100) || 0).toFixed(SHORT_DECIMALS_PLACES);
  operation.amount = (valueNoContractNoTaxes / operation.customerRate).toFixed(SHORT_DECIMALS_PLACES);
  operation.vet = ((operation.totalValue / operation.amount || 0)).toFixed(4);
}

const BIG_DECIMALS_PLACES = 5;

const SHORT_DECIMALS_PLACES = 2;

export default {
  calculateOperationRemittance,
  setBaseValuesOperationRemittance,
  resetSpreads,
  getSpreadFromCustomerRateRemittance,
  setBaseValuesOperationExchange,
  calculateOperationExchange,
  getSpreadFromCustomerRateExchange,
  validationOperationLimitNotExpired,
  validationOperationHasLimit,
  REFRESH_RATE_TIME,
  calculateOperationExchangeFromBrl,
  calculateOperationRemittanceFromBrl,
  BIG_DECIMALS_PLACES,
  SHORT_DECIMALS_PLACES,
};