import api from '../api';

function add(nature) {
  nature = nature || {};

  return new Promise((resolve, reject) => {
    return api.post('/operation-natures', nature)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(nature) {
  nature = nature || {};

  return new Promise((resolve, reject) => {
    return api.put(`/operation-natures/${nature.id}`, nature)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findAll() {
  return new Promise((resolve, reject) => {
    return api.get('/operation-natures')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.get(`/operation-natures/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  findId,
  add,
  update,
};