import { TYPE_ENVIRONMENT } from '../constants/global';
function getURLFrontEnd() {

  switch (process.env.PUBLISH) {
    case TYPE_ENVIRONMENT.HTML: return process.env.URL_HML;
    case TYPE_ENVIRONMENT.PROD: return process.env.URL_PROD;
    default: return process.env.URL_LOCALHOST;
  }

}

export default {
  getURLFrontEnd
}