export default class ExchangeBankContactModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.name = obj.name;
    this.sector = obj.sector;
    this.cellphone = obj.cellphone;
    this.landline = obj.landline;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}