<template>
  <v-row
    no-gutters
    class="box pb-0"
  >
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="12">
          <h1 class="titulo">Lucro / Remessas Internacionais</h1>
          <v-divider />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        justify="end"
      >
        <v-col class="d-flex align-end">
          <v-btn
            class="btn-primary float-left"
            text
            @click="openModalUpdateComissions"
          >
            Atualizar comissões em massa
          </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
        </v-col>

        <v-col class="shrink d-flex align-end pl-2">
          <operation-filter-drawer
            v-model="filters"
            :bank-list="bankList"
            :currency-list="currencyList"
            :status-list="[statusId]"
            :exchange-type-id="exchangeTypeId"
            filter-this-month
            @filter="getOperations"
          />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        class="pt-2"
      >
        <v-col
          v-if="!operations.length"
          cols="12"
        >
          <EmptyTable
            title="Nenhum registro encontrado"
            icon="far fa-hand-holding-usd"
            subtitle="Não há operações de Câmbio Remessa para o período selecionado."
          />
        </v-col>

        <v-col
          v-else
          cols="12"
        >
          <v-card class="box card-cambio mb-4">
            <v-card-title>
              <h1 class="titulo-modal">Totais</h1>
            </v-card-title>
            <v-row class="pa-5">
              <v-col
                cols="12"
                md="3"
              >
                <h1 class="title-sum">Nº Operações</h1>
                <span class="value-sum">{{ operations.length || 0 }}</span>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <h1 class="title-sum">Total Operado</h1>
                <span class="value-sum">{{ sumSubtotal | formatCurrency() }}</span>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <h1 class="title-sum">Lucro Bruto</h1>
                <span class="value-sum">{{ sumGrossProfit || 0 | formatCurrency() }}</span>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <h1 class="title-sum">PIS/COFINS</h1>
                <span class="value-sum">{{ sumPisCofins || 0 | formatCurrency() }}</span>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <h1 class="title-sum">Lucro Liquido</h1>
                <span class="value-sum">{{ sumNetProfit || 0 | formatCurrency() }}</span>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <h1 class="title-sum">Lucro Corban Master</h1>
                <span class="value-sum">{{
                  sumCambioHojeComissionValue || 0 | formatCurrency()
                }}</span>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <h1 class="title-sum">Lucro Corban</h1>
                <span class="value-sum">{{ sumAgentValue || 0 | formatCurrency() }}</span>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <h1 class="title-sum">Lucro Corban Indicador</h1>
                <span class="value-sum">{{ sumIndicatorValue || 0 | formatCurrency() }}</span>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <h1 class="title-sum">Lucro Banco</h1>
                <span class="value-sum">{{ sumBankComissionValue || 0 | formatCurrency() }}</span>
              </v-col>
              <v-col
                cols="12"
                md="3"
                v-if="sumCashBackContractValue"
              >
                <h1 class="title-sum">Cashback Tarifa</h1>
                <span class="value-sum">{{
                  sumCashBackContractValue || 0 | formatCurrency()
                }}</span>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="card-cambio">
            <v-data-table
              :expanded.sync="expanded"
              show-expand
              :headers="columns"
              :items="operations"
              :search="search"
              sort-by="effectiveOperationDate"
              sort-desc
              :items-per-page="10"
              :custom-filter="caseInsensitiveAccentsInsensitive"
              @click:row="clickRow"
            >
              <!-- Closed Content -->
              <template #[`item.customer.name`]="{ item }">
                <span v-if="item.customer.isPJ()"
                  >{{ item.customer.companyName }}
                  <span>
                    <InfoTooltip
                      icon-size="1x"
                      :label="'Nome fantasia: ' + item.customer.fantasyName"
                    />
                  </span>
                </span>
                <span v-else>{{ item.customer.name }}</span>
              </template>

              <template #[`item.effectiveOperationDate`]="{ item }">
                <v-edit-dialog
                  save-text="Salvar"
                  cancel-text="Cancelar"
                  :return-value.sync="item.effectiveOperationDate"
                  large
                  @save="saveUpdatedOperation(item)"
                >
                  <span>
                    {{ item.effectiveOperationDate | date }}
                  </span>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <i
                        class="far fa-pencil icon-button"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <template #input>
                    <Datepicker
                      v-model="item.effectiveOperationDate"
                      label="Data"
                      autofocus
                      v-disabled-icon-focus
                      dense
                      class="pt-2"
                    />
                  </template>
                </v-edit-dialog>
              </template>
              <template #[`item.amount`]="{ item }">
                <span>{{ item.amount | formatCurrency(item.currency.code) }}</span>
              </template>
              <template #[`item.customerRate`]="{ item }">
                <span>{{ item.customerRate | formatCurrency('BRL', 5) }}</span>
              </template>

              <template #[`item.bankRate`]="{ item }">
                <v-edit-dialog
                  save-text="Salvar"
                  cancel-text="Cancelar"
                  :return-value.sync="item.bankRate"
                  large
                  @save="saveUpdatedOperation(item)"
                >
                  <span>{{ item.bankRate | formatCurrency('BRL', 5) }}</span>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <i
                        class="far fa-pencil"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <template #input>
                    <MoneyField
                      label="Taxa do Banco"
                      prefix="R$ "
                      v-model="item.bankRate"
                      :precision="6"
                      autofocus
                      class="pt-2"
                    />
                  </template>
                </v-edit-dialog>
              </template>

              <template #[`item.administrative.grossProfit`]="{ item }">
                <span>{{ item.administrative.grossProfit | formatCurrency }}</span>
              </template>
              <template #[`item.administrative.agentValue`]="{ item }">
                <span>{{ item.administrative.agentValue | formatCurrency }}</span>
              </template>
              <template #[`item.administrative.indicatorValue`]="{ item }">
                <span>{{ item.administrative.indicatorValue | formatCurrency }}</span>
              </template>

              <!-- Expanded Content -->
              <template #expanded-item="{ item }">
                <td :colspan="columns.length + 1">
                  <v-card class="box card-cambio my-5">
                    <v-row>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <span class="expand-title">Nº da OP</span>
                        <p class="expand-value">{{ item.id }}</p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <span class="expand-title">Tipo de operação</span>
                        <p>
                          <v-chip
                            v-if="item.exchangeBuy"
                            small
                            label
                            color="teal lighten-4"
                          >
                            Compra
                          </v-chip>
                          <v-chip
                            v-else
                            small
                            label
                            color="red lighten-4"
                          >
                            Venda
                          </v-chip>
                        </p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <span class="expand-title">Pis/Cofins</span>
                        <p class="expand-value">
                          {{ item.administrative.pisCofins | formatCurrency }}
                        </p>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                      >
                        <span class="expand-title">Banco</span>
                        <p class="expand-value">
                          {{ item.bank.fantasyName }}
                          <InfoTooltip
                            icon-size="1x"
                            :label="'Comissão do Banco: ' + item.administrative.comission + '%'"
                          />
                        </p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <span class="expand-title">Corban</span>
                        <p class="expand-value">
                          {{ item.administrative.agentData.name }}
                          <InfoTooltip
                            icon-size="1x"
                            :label="'Comissão do Corban: ' + item.administrative.agent + '%'"
                          />
                        </p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        v-if="item.administrative.indicatorData?.id"
                      >
                        <span class="expand-title">Corban Indicador</span>
                        <p class="expand-value">
                          {{ item.administrative.indicatorData.name }}
                          <InfoTooltip
                            icon-size="1x"
                            :label="
                              'Comissão do Corban Indicador: ' + item.administrative.indicator + '%'
                            "
                          />
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <!-- Comission Mass Update Modal -->
          <v-dialog
            persistent
            max-width="600px"
            v-model="modalUpdateComissions"
          >
            <v-card>
              <v-card-title>
                <h1 class="titulo-modal">Atualizar Comissões em Massa</h1>
              </v-card-title>
              <v-divider />
              <v-card-text class="pt-5">
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-form
                      id="add-operation"
                      ref="form"
                    >
                      <v-row>
                        <v-col
                          class="py-0"
                          cols="12"
                          md="12"
                        >
                          <NumberField
                            v-model="updatedComission"
                            outlined
                            dense
                            label="Comissão(%)"
                            suffix="%"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-col
                  cols="12"
                  md="12"
                  class="text-right"
                >
                  <v-btn
                    text
                    class="btn-primary mr-2"
                    @click="massUpdateComissions"
                  >
                    Atualizar
                  </v-btn>
                  <v-btn
                    text
                    class="btn-tertiary"
                    @click="modalUpdateComissions = false"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// Api
import apiOperation from '@/api/operation/operation-api';
import apiCurrency from '@/api/spread/currency-api';
import apiExchangeBank from '@/api/exchangeBank/exchange-bank-api';

// Models
import OperationModel from '@/model/operation-model';
import OperationFiltersModel from '@/model/operation-filters-model';
import CurrencyModel from '@/model/currency-model';
import ExchangeBankModel from '@/model/exchange-bank-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinDataTableFilters from '@/mixin/mixin-data-table-filters';
import mixinExpandedDataTable from '@/mixin/mixin-data-table-expanded';

// Utils
import dateUtils from '../../../../common/utils/date';
import numberUtils from '../../../../common/utils/number';

// Components
import Datepicker from '@/components/comum/Datepicker.vue';
import EmptyTable from '@/components/comum/EmptyTable.vue';
import MoneyField from '@/components/comum/MoneyField.vue';
import InfoTooltip from '@/components/comum/InfoTooltip';
import NumberField from '@/components/comum/NumberField';
import OperationFilterDrawer from '@/components/comum/OperationFilterDrawer.vue';

// Constants
import { OPERATION_WORKFLOW } from '../../../../common/workflows/operationWorkflow';
import { OPERATION_TYPE } from '../../../../common/constants/generic/types';

export default {
  name: 'RemittanceOperationManagementReport',
  mixins: [mixinMessage, mixinDataTableFilters, mixinExpandedDataTable],
  components: {
    Datepicker,
    EmptyTable,
    MoneyField,
    InfoTooltip,
    NumberField,
    OperationFilterDrawer
  },
  data() {
    return {
      search: '',
      operations: [],
      columns: [
        { text: 'Data', value: 'effectiveOperationDate', align: 'left', sortable: true },
        { text: 'Cliente / Razão Social', value: 'customer.name', align: 'left', sortable: true },
        { text: 'Valor M.E', value: 'amount', align: 'left', sortable: true },
        { text: 'Taxa do Cliente', value: 'customerRate', align: 'left', sortable: true },
        { text: 'Taxa do Banco', value: 'bankRate', align: 'left', sortable: true },
        { text: 'Lucro Bruto', value: 'administrative.grossProfit', align: 'left', sortable: true },
        { text: 'Lucro Corban', value: 'administrative.agentValue', align: 'left', sortable: true },
        {
          text: 'Lucro Indicador',
          value: 'administrative.indicatorValue',
          align: 'left',
          sortable: true
        }
      ],
      filters: new OperationFiltersModel(),
      currencyList: [],
      bankList: [],
      updatedComission: 0,
      modalUpdateComissions: false,
      statusId: OPERATION_WORKFLOW.LIQUIDADA.id,
      exchangeTypeId: OPERATION_TYPE.REMMITANCE.id
    };
  },
  watch: {},
  mounted() {
    this.getCurrencies();
    this.getBankList();
    this.getOperations();
  },
  computed: {
    sumSubtotal() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.operationSubTotal);
      }, 0);
    },
    sumGrossProfit() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.grossProfit);
      }, 0);
    },
    sumNetProfit() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.netProfit);
      }, 0);
    },
    sumAgentValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.agentValue);
      }, 0);
    },
    sumIndicatorValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.indicatorValue);
      }, 0);
    },
    sumCambioHojeComissionValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.cambioHojeComissionValue);
      }, 0);
    },
    sumPisCofins() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.pisCofins);
      }, 0);
    },
    sumCashBackContractValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.cashbackContractValue);
      }, 0);
    },
    sumBankComissionValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.bankComissionValue);
      }, 0);
    }
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    formatCurrency(value, code, precison) {
      code = code || 'BRL';

      return numberUtils.formatCurrency(code, value, precison);
    }
  },
  methods: {
    getOperations() {
      this.$eventBus.$emit('show-loading', true);

      apiOperation
        .find(this.filters)
        .then((response) => {
          this.operations = response.data.map((o) => {
            let operation = new OperationModel(o);

            return operation;
          });
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
          }

          this.operations = [];
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },
    getCurrencies() {
      if (this.currencyList[0]) {
        return;
      }

      apiCurrency
        .findAll()
        .then((response) => {
          this.currencyList = response.data.map((c) => new CurrencyModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },
    getBankList() {
      apiExchangeBank
        .findAll()
        .then((response) => {
          this.bankList = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
          this.loading = false;
        });
    },

    saveUpdatedOperation(operation) {
      apiOperation
        .update(operation)
        .then((response) => {
          let updatedOperation = new OperationModel(response.data);
          let index = this.operations.findIndex((o) => o.id == updatedOperation.id);
          this.operations.splice(index, 1, updatedOperation);
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    openModalUpdateComissions() {
      this.updatedComission = 0;
      this.modalUpdateComissions = true;
    },
    massUpdateComissions() {
      this.$eventBus.$emit('show-loading', true);

      apiOperation
        .massUpdateComissions(this.filters, this.updatedComission)
        .then(() => {
          this.getOperations();
          this.modalUpdateComissions = false;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.title-sum {
  font-size: 0.8rem;
  font-weight: 500;
}

.value-sum {
  color: var(--v-primary-base);
  font-size: 1.5rem;
  font-weight: 300;
}

i.far.fa-pencil {
  color: var(--v-primary-base);
}
</style>
