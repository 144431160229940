import Person from '../../../../common/models/Person';

export default class DashboardCustomerModel extends Person {
  constructor(obj) {
    obj = obj || {};
    super(obj);

    this.id = obj.id;

    this.setPersonType();
  }
}
