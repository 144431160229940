<template>
  <v-card
    class="card-cambio"
    outlined
  >
    <v-list-item
      three-line
      :style="boederColorStyle"
    >
      <v-list-item-content>
        <v-list-item-subtitle>{{ description }}</v-list-item-subtitle>

        <v-list-item-title class="text-h5 mb-1 font-weight-bold">
          {{ value }}
        </v-list-item-title>
      </v-list-item-content>
      <span
        class="fa-stack fa-1x"
        v-if="icon"
      >
        <i
          class="far fa-badge fa-stack-2x"
          :style="iconColorStyle"
        />
        <i
          :class="iconClass"
          :style="iconColorStyle"
        />
      </span>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: 'CardDashboard',
  props: {
    description: { type: String, default: '' },
    value: { type: [String, Number], default: '' },
    icon: { type: Boolean, default: false },
    color: { type: String, default: '' },
    iconClass: { type: String, default: '' }
  },
  computed: {
    iconColorStyle() {
      return `color: ${this.color}`;
    },
    boederColorStyle() {
      return `border-left: 4px solid ${this.color}`;
    }
  }
};
</script>
<style lang="scss" scoped></style>
