<template>
  <v-row v-if="!isPF">
    <v-col
      cols="12"
      md="12"
    >
      <div class="box card-cambio">
        <h1>Sócios e Acionistas</h1>
        <v-divider class="py-3" />
        <PJPartnersShareholders v-model="customer" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import PJPartnersShareholders from "@/components/form-customer/PJ/partnersShareholders/PJPartnersShareholders.vue";

// Constants
import { REGISTER_TYPE } from "@/constants/general-constants.js";

export default {
  name: "PartnersAndShareholders",
  components: { PJPartnersShareholders },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  computed: {
    isPF() {
      return this.customer &&
        this.customer.registerType.id == REGISTER_TYPE.LEGAL_PERSON.id
        ? false
        : true;
    },
  },
  data() {
    return {
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
      customer: this.customerProp,
    };
  },
};
</script>

<style></style>