<template>
  <v-menu
    open-on-hover
    bottom
    offset-x
  >
    <template #activator="{ on, attrs }">
      <i
        :class="'far fa-info-circle icon-tooltip fa-' + iconSize"
        v-bind="attrs"
        v-on="on"
      />
    </template>

    <v-card
      class="card-cambio"
      width="350px"
    >
      <!-- eslint-disable -->
      <!-- Estamos usando o v-textarea pois foi a forma que encontramos de conseguir exibir o texto com 
       quebra de linha. Deverá ser alterado para o Tiny, quando ajustar o bug da TC-544 -->
      <v-card-text>
        <h2>Descrição da natureza</h2>
        <v-divider></v-divider>
        <v-textarea v-model="label" readonly rows="10">
        </v-textarea>
      </v-card-text>
      <!-- eslint-enable -->
    </v-card>
  </v-menu>
</template>

<script>

export default {
  name: "InfoPopover",
  props: {
    label: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      required: false,
      default: '1.6x'
    }
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: 400;
  font-size: 1rem;
  color: var(--v-primary-base);
}
</style>
