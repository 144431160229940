<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <h1>Países com Acordo Bilateral</h1>
        <v-divider class="py-3 mt-3" />
        <v-row>
          <v-col
            cols="12"
            md="10"
          >
            <v-btn
              class="btn-primary ml-0"
              text
              @click="openAddAgreementModal"
            >
              <i class="far fa-plus" />
              Adicionar
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            lg="3"
            sm="6"
            offset-lg="9"
            offset-sm="6"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-card class="card-cambio">
              <v-data-table
                :headers="columns"
                :items="nature.bilateralAgreementCountries"
                :search="search"
                sort-by="id"
                sort-desc
                :items-per-page="10"
              >
                <template #[`item.country`]="{ item }">
                  {{ item.country | formatCountry }}
                </template>
                <template #[`item.actions`]="{ item }">
                  <div class="buttons-column">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          class="btn-primary"
                          text
                          @click="editAgreementModal(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="far fa-pencil" />
                          Editar
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- Add Agreement Modal -->
    <v-dialog
      persistent
      scrollable
      eager
      min-width="40vw"
      max-width="100vh"
      v-model="addAgreementModal"
      :key="key"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Acordo Bilateral</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-agreement"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <CountryField
                      label="País"
                      v-model="agreement.country"
                    />
                  </v-col>

                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <NumberField
                      v-model="agreement.ir"
                      outlined
                      dense
                      label="IR Venda(%)"
                      suffix="%"
                      :precisao-decimal="6"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              v-if="!update"
              text
              class="btn-primary mr-2"
              @click="addAgreement"
            >
              Salvar
            </v-btn>
            <v-btn
              v-else-if="update"
              text
              class="btn-primary mr-2"
              @click="updateAgreement"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="addAgreementModal = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import apiBilateralAgreement from "@/api/configuration/bilateral-agreement-country-api";

// Components
import NumberField from "@/components/comum/NumberField";
import CountryField from "@/components/comum/CountryField.vue";

// Models
import BilateralAgreementCountryModel from "@/model/bilateral-agreement-country-model";

// Utils
import countryUtils from "@/utils/country";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

export default {
name: "BilateralAgreementCountries",
  mixins: [mixinMessage],
  components: {
    NumberField,
    CountryField,
  },
  model: {
    prop: "natureProp",
    event: "onChange",
  },
  props: {
    natureProp: {
      type: Object,
    },
  },
  data() {
    return {
      nature: this.natureProp,
      search: "",
      columns: [
        { text: "País", value: "country", align: "left", sortable: true },
        { text: "IR Venda(%)", value: "ir", align: "right", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      agreement: new BilateralAgreementCountryModel(),
      addAgreementModal: false,
      update: false,
      key: 0,
    };
  },
  filters: {
    formatCountry(value) {
      return countryUtils.formatCountry(value);
    },
  },
  mounted() {  },
  computed: {  },
  methods: {
    openAddAgreementModal() {
      this.update = false;
      this.agreement = new BilateralAgreementCountryModel();
      this.addAgreementModal = true;
      this.key = this.key + 1;
    },

    editAgreementModal(agreement) {
      this.update = true;
      this.agreement = Object.assign({}, agreement);
      this.addAgreementModal = true;
      this.key = this.key + 1;
    },

    addAgreement() {
      if (!this.nature.id) {
        this.nature.bilateralAgreementCountries.push(new BilateralAgreementCountryModel(this.agreement));
        this.addAgreementModal = false;

        return;
      }

      apiBilateralAgreement
        .add(this.agreement, this.nature.id)
        .then((response) => {
          this.nature.bilateralAgreementCountries.push(new BilateralAgreementCountryModel(response.data));
          this.addAgreementModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    updateAgreement() {
      let natureId = parseInt(this.nature.id);
      apiBilateralAgreement
        .update(this.agreement, natureId)
        .then((response) => {
          let index = this.nature.bilateralAgreementCountries.findIndex((a) => a.id == this.agreement.id);
          this.nature.bilateralAgreementCountries.splice(
            index,
            1,
            new BilateralAgreementCountryModel(response.data)
          );
          this.addAgreementModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  }
};
</script>

<style scoped>
.buttons-column{
  float: right;
}
</style>
