import utilsDate from "../../../utils/date";
import utilsNumber from "../../../utils/number";
import system from "../../../utils/system";

function get(customer, token, bank, externalAccessDocumentToken, complementaryInfo) {
  return [
    {
      name: "Cliente",
      children: [
        {
          name: "Código",
          attribute: "Codigo_Cliente",
          title: "Código do cliente",
          inline: true,
          content: () => {
            return customer?.id || '';
          }
        },
        {
          name: "Nome ou razão social",
          attribute: "Nome_Ou_Razao_Social_Cliente",
          title: "Nome ou razão social do cliente",
          inline: true,
          content: () => {

            if (customer?.isPF())
              return customer?.name || '';
            else if (customer?.isPJ())
              return customer?.companyName || '';
            else
              return '';
          }
        },
        {
          name: "Primeiro nome",
          attribute: "Primeiro_Nome_Cliente",
          title: "Primeiro nome do cliente",
          inline: true,
          content: () => {

            if (customer?.isPF())
              return customer?.name?.toString().trim().split(' ')[0] || '';
            else
              return '';
          }
        },
        {
          name: "Nome fantasia",
          attribute: "Nome_Fantasia_Cliente",
          title: "Nome fantasia do cliente",
          inline: true,
          content: () => {
            if (customer?.isPJ())
              return customer?.fantasyName || '';
            else
              return '';
          }
        },
        {
          name: "CPF ou CNPJ",
          attribute: "CPF_OU_CNPJ_CLIENTE",
          title: "CPF ou CNPJ do cliente ",
          inline: true,
          content: () => {
            return customer?.cpfCnpj || '';
          }
        },
        {
          name: "Sexo",
          attribute: "Sexo",
          title: "Sexo do cliente",
          inline: true,
          content: () => {
            return customer?.sex?.replace("M", "Masculino").replace("F", "Feminino") || '';
          }
        },
        {
          name: "Data de nascimento",
          attribute: "Data_De_Nascimento",
          title: "Data de nascimento do cliente",
          inline: true,
          content: () => {
            return customer?.birthDate && utilsDate.maskDateIso(customer?.birthDate) || '';
          }
        },
        {
          name: "Celular",
          attribute: "Celular_Cliente",
          title: "Celular do cliente",
          inline: true,
          content: () => {
            return customer?.cellphone || '';
          }
        },
        {
          name: "Setor de atividade (PJ)",
          attribute: "Setor_Atividade_Cliente",
          title: "Setor de atividade cliente",
          inline: true,
          content: () => {
            return customer?.additional?.sectorActivity || '';
          }
        },
        {
          name: "Data da fundação (PJ)",
          attribute: "Data_Fundacao_Cliente",
          title: "Data da fundação cliente",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.additional?.foundingDate) || '';
          }
        },
        {
          name: "Data da última alteração (PJ)",
          attribute: "Data_Ultima_Alteracao_Empresa_Cliente",
          title: "Data da última alteração da empresa",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.additional?.lastChangeDate) || '';
          }
        },
        {
          name: "Data atualização cadastro",
          attribute: "Data_Atualizacao_Cadastro_Cliente",
          title: "Data de atualização do cadastro do cliente",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.updatedAt) || '';
          }
        },
        {
          name: "Data emissão documento",
          attribute: "Data_Emissao_Documento_Cliente",
          title: "Data da emissão do documento do cliente",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.documentIssueDate) || '';
          }
        },
        {
          name: "Data de validade documento",
          attribute: "Data_Validade_Documento_Cliente",
          title: "Data da validade do documento do cliente",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.documentDueDate) || '';
          }
        },
        {
          name: "E-mail principal",
          attribute: "Email_Principal_Cliente",
          title: "E-mail principal do cliente",
          inline: true,
          content: () => {
            return customer?.email || '';
          }
        },
        {
          name: "E-mail secundario",
          attribute: "Email_Secundario_Cliente",
          title: "E-mail secundario do cliente",
          inline: true,
          content: () => {
            return customer?.secondEmail || '';
          }
        },
        {
          name: "Endereço completo",
          attribute: "Endereco_Cliente",
          title: "Endereço completo do cliente",
          inline: true,
          content: () => {
            if (customer?.isPF())
              return _getAddress(customer?.residentialAddress);
            else if (customer?.isPJ())
              return _getAddress(customer?.professionalAddress)
            else
              return '';
          }
        },
        {
          name: "URL acesso externo",
          attribute: "URL_Acesso_Externo_Cliente",
          title: "URL para acesso externo - cliente",
          inline: true,
          content: () => {
            let urlBase = system.getURLFrontEnd();

            return `
            <center>
              <a href="${urlBase}/customers/external-acess/${token}" target="_blank" style="
                text-decoration: none;
                padding: 8px;
                border: none;
                width: 100px;
                display: block;
                text-align: center;
                cursor: pointer;
                background-color:#3333cc;
                border-radius: 3px;
                color: #fff;
                font-weight: 600;">
                Clique aqui
              </a>
            </center>`;
          }
        },
        {
          name: "Perfil",
          attribute: "Perfil_Cliente",
          title: "Perfil do cliente",
          inline: true,
          content: () => {
            return customer?.registerType?.description || '';
          }
        },
        {
          name: "Estado Cívil",
          attribute: "Estado_Civil_Cliente",
          title: "Estado Cívil do cliente",
          inline: true,
          content: () => {
            return customer?.additional?.maritalStatus.description || '';
          }
        },
        {
          name: "Regime de Casamento",
          attribute: "Regime_Casamento_Cliente",
          title: "Regime de Casamento do cliente",
          inline: true,
          content: () => {
            return customer?.additional?.marriageSystem.description || '';
          }
        },
        {
          name: "Nome do Cônjuge",
          attribute: "Nome_Conjuge_Cliente",
          title: "Nome do Cônjuge do cliente",
          inline: true,
          content: () => {
            return customer?.additional?.spouseName || '';
          }
        },
        {
          name: "CPF do Cônjuge",
          attribute: "CPF_Conjuge_Cliente",
          title: "CPF do Cônjuge do cliente",
          inline: true,
          content: () => {
            return customer?.additional?.spouseCPF || '';
          }
        },
        {
          name: "Renda média mensal",
          attribute: "Renda_Media_Mensal_Cliente",
          title: "Renda média mensal do cliente",
          inline: true,
          content: () => {
            // TODO: Aqui não sei se pode ser outro formato de moeda, mas a principio vou deixar portugues brasil como default
            return utilsNumber.formatCurrency('BRL', customer?.additional?.averageMonthlyIncome) || '';
          }
        },
        {
          name: "Profissão",
          attribute: "Profissao_Cliente",
          title: "Profissão do cliente",
          inline: true,
          content: () => {
            return customer?.occupation?.profession || '';
          }
        },
        {
          name: "Logradouro",
          attribute: "Logradouro_Cliente",
          title: "Logradouro do cliente",
          inline: true,
          content: () => {
            if (customer?.isPF())
              return customer?.residentialAddress?.street || '';
            else if (customer?.isPJ())
              return customer?.professionalAddress?.street || '';
            else
              return '';
          }
        },
        {
          name: "Número do endereço",
          attribute: "Numero_Cliente",
          title: "Numero do endereço do cliente",
          inline: true,
          content: () => {
            if (customer?.isPF())
              return customer?.residentialAddress?.number || '';
            else if (customer?.isPJ())
              return customer?.professionalAddress?.number || '';
            else
              return '';
          }
        },
        {
          name: "Complemento do endereco",
          attribute: "Complemento_Endereco_Cliente",
          title: "Complemento do endereço do cliente",
          inline: true,
          content: () => {
            if (customer?.isPF())
              return customer?.residentialAddress?.complement || '';
            else if (customer?.isPJ())
              return customer?.professionalAddress?.complement || '';
            else
              return '';
          }
        },
        {
          name: "Bairro",
          attribute: "Bairro_Cliente",
          title: "Bairro do cliente",
          inline: true,
          content: () => {
            if (customer?.isPF())
              return customer?.residentialAddress?.neighborhood || '';
            else if (customer?.isPJ())
              return customer?.professionalAddress?.neighborhood || '';
            else
              return '';
          }
        },
        {
          name: "Cidade",
          attribute: "Cidade_Cliente",
          title: "Cidade do cliente",
          inline: true,
          content: () => {
            if (customer?.isPF())
              return customer?.residentialAddress?.city || '';
            else if (customer?.isPJ())
              return customer?.professionalAddress?.city || '';
            else
              return '';
          }
        },
        {
          name: "Estado",
          attribute: "Estado_Cliente",
          title: "Estado do cliente",
          inline: true,
          content: () => {
            if (customer?.isPF())
              return customer?.residentialAddress?.state || '';
            else if (customer?.isPJ())
              return customer?.professionalAddress?.state || '';
            else
              return '';
          }
        },
        {
          name: "Cep",
          attribute: "Cep_Cliente",
          title: "Cep do cliente",
          inline: true,
          content: () => {
            if (customer?.isPF())
              return customer?.residentialAddress?.cep || '';
            else if (customer?.isPJ())
              return customer?.professionalAddress?.cep || '';
            else
              return '';
          }
        },
        {
          name: "Endereço delivery",
          attribute: "Endereco_Delivery_Cliente",
          title: "Endereço de entrega ou correspondência",
          inline: true,
          content: () => {
            return _getAddress(customer?.deliveryAddress);
          }
        },
        {
          name: "Logradouro delivery",
          attribute: "Logradouro_Delivery_Cliente",
          title: "Logradouro delivery do cliente",
          inline: true,
          content: () => {
            return customer?.deliveryAddress?.street || '';
          }
        },
        {
          name: "Número do endereço delivery",
          attribute: "Numero_Endereco_Delivery_Cliente",
          title: "Numero do endereço delivery do cliente",
          inline: true,
          content: () => {
            return customer?.deliveryAddress?.number || '';
          }
        },
        {
          name: "Complemento do endereco delivery",
          attribute: "Complemento_Endereco_Delivery_Cliente",
          title: "Complemento do endereço delivery do cliente",
          inline: true,
          content: () => {
            return customer?.deliveryAddress?.complement || '';
          }
        },
        {
          name: "Bairro delivery",
          attribute: "Bairro_Delivery_Cliente",
          title: "Bairro delivery do cliente",
          inline: true,
          content: () => {
            return customer?.deliveryAddress?.neighborhood || '';
          }
        },
        {
          name: "Cidade delivery",
          attribute: "Cidade_Delivery_Cliente",
          title: "Cidade delivery do cliente",
          inline: true,
          content: () => {
            return customer?.deliveryAddress?.city || '';
          }
        },
        {
          name: "Estado delivery",
          attribute: "Estado_Delivery_Cliente",
          title: "Estado delivery do cliente",
          inline: true,
          content: () => {
            return customer?.deliveryAddress?.state || '';
          }
        },
        {
          name: "Cep delivery",
          attribute: "Cep_Delivery_Cliente",
          title: "Cep delivery do cliente",
          inline: true,
          content: () => {
            return customer?.deliveryAddress?.cep || '';
          }
        },
        {
          name: "Banco nacional",
          attribute: "Banco_Nacional_Cliente",
          title: "Banco nacional do cliente",
          inline: true,
          content: () => {
            // TODO: O codigo e nome do banco devem sempre existir no modelo.
            let bank = customer?.nationalBanks?.[0];
            return `${bank?.code || bank?.bank} ${bank?.name || ""}` || '';
          }
        },
        {
          name: "Agência nacional",
          attribute: "Agencia_Nacional_Cliente",
          title: "Agência nacional do cliente",
          inline: true,
          content: () => {
            let bank = customer?.nationalBanks?.[0];
            return bank?.agency || '';
          }
        },
        {
          name: "Conta nacional",
          attribute: "Conta_Nacional_Cliente",
          title: "Conta nacional do cliente",
          inline: true,
          content: () => {
            let bank = customer?.nationalBanks?.[0];
            return `${bank?.account}-${bank?.digit}` || '';
          }
        },
        {
          name: "Natureza da operação",
          attribute: "Natureza_Operacao_Cliente",
          title: "Natureza da operação do cliente",
          inline: true,
          content: () => {
            let operationNatureNameList = customer?.exchangeBanks?.find((b) => (b.bank.id == bank?.id))?.operationNatures?.map(i => i.name);
            let listItens = '';

            for (let i = 0; i < operationNatureNameList.length; i++) {
              const nature = operationNatureNameList[i];
              listItens = listItens + `<li>${nature}</li>`;
            }

            return !listItens ? '' : `
            <ul>
              ${listItens}
            </ul>
            `;
          }
        },
        {
          name: "Limite atribuído",
          attribute: "Limite_Atribuido_Cliente",
          title: "Limite atribuído do cliente",
          inline: true,
          content: () => {
            let exchangeBank = customer?.exchangeBanks?.find((b) => (b.bank.id == bank?.id));

            if (exchangeBank?.bank.useUsdLimit) {
              if (exchangeBank?.limit.isInOut) {
                return `IN(Entrada): ${utilsNumber.formatCurrency('USD', exchangeBank?.limit.valueIn) || ''} / OUT(Saída): ${utilsNumber.formatCurrency('USD', exchangeBank?.limit.valueOut) || ''}`;
              }

              return utilsNumber.formatCurrency('USD', exchangeBank?.limit.value) || '';
            }

            if (exchangeBank?.limit.isInOut) {
              return `IN(Entrada): ${utilsNumber.formatCurrency('BRL', exchangeBank?.limit.valueIn) || ''} / OUT(Saída): ${utilsNumber.formatCurrency('BRL', exchangeBank?.limit.valueOut) || ''}`;
            }

            return utilsNumber.formatCurrency('BRL', exchangeBank?.limit.value) || '';
          }
        },
        {
          name: "Validade limite",
          attribute: "Validade_Limite_Cliente",
          title: "Validade limite do cliente",
          inline: true,
          content: () => {
            let exchangeBank = customer?.exchangeBanks?.find((b) => (b.bank.id == bank?.id));

            return utilsDate.maskDateIso(exchangeBank?.limit.expireAt) || '';
          }
        },
        {
          name: "URL acesso externo documentos do cliente",
          attribute: "URL_Acesso_Externo_Documentos_Cliente",
          title: "URL para acesso externo de documentos - cliente",
          inline: true,
          content: () => {
            let urlBase = system.getURLFrontEnd();

            return `
            <center>
              <a href="${urlBase}/customer/documents/external-acess/${externalAccessDocumentToken}" target="_blank" style="
                text-decoration: none;
                padding: 8px;
                border: none;
                width: 100px;
                display: block;
                text-align: center;
                cursor: pointer;
                background-color:#3333cc;
                border-radius: 3px;
                color: #fff;
                font-weight: 600;"
              >
                Clique aqui para acesso aos documentos
              </a>
            </center>`;
          }
        },
        {
          name: "Informação complementar do cliente",
          attribute: "Informacao_Complementar_Cliente",
          title: "Informação complementar - cliente",
          inline: true,
          content: () => {
            return complementaryInfo || '';
          }
        },
        {
          name: "Nº do documento",
          attribute: "Numero_Documento_Cliente",
          title: "Número do documento do cliente",
          inline: true,
          content: () => {
            return customer?.documentNumber || '';
          }
        },

      ]
    }
  ]
}

function _getAddress(address) {
  let street = address?.street || '';
  let number = address?.number || '';
  let complement = address?.complement || '';
  let neighborhood = address?.neighborhood || '';
  let city = address?.city || '';
  let state = address?.state || '';
  let cep = address?.cep || '';

  return `${street} - ${number} - ${complement} - ${neighborhood} - ${city} - ${state} - ${cep}`;
}

export default { get }