// Tracking
import mixpanel from "mixpanel-browser";


const mixinMessage = {
  data() {
    return {};
  },
  methods: {
    sendMessage(message, type, timeout) {
      let snackbar = { type, message, timeout };
      mixpanel.track(type, { message: message });
      this.$eventBus.$emit('snackbar-message', snackbar);
    }
  }
};

export default mixinMessage;