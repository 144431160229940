import api from '../api';

function add(customer) {
  customer = customer || {};

  return new Promise((resolve, reject) => {
    return api.post('/customers', customer)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(customer) {
  customer = customer || {};

  return new Promise((resolve, reject) => {
    return api.put(`/customers/${customer.id}`, customer)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findAll(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/customers', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.get(`/customers/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function inactive(customer) {
  customer = customer || {};

  return new Promise((resolve, reject) => {
    return api.patch(`/customers/${customer.id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function updateStatus(customerId, statusUpdateDto) {
  statusUpdateDto = statusUpdateDto || {};

  return new Promise((resolve, reject) => {
    return api.put(`/customers/${customerId}/update-status`, statusUpdateDto)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function addLimit(idCustomer, idExchangeBank, limit) {
  idCustomer = idCustomer || {};
  idExchangeBank = idExchangeBank || {};
  limit = limit || {};

  return new Promise((resolve, reject) => {
    return api.post(`/customers/${idCustomer}/bank/${idExchangeBank}/limits`, limit)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findExchangeBanks(id, idType) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.get(`/customers/${id}/exchange-type/${idType}/banks`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function getLimits(filters) {
  return new Promise((resolve, reject) => {
    return api.get(`/customers/limits/`, { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  findId,
  inactive,
  add,
  update,
  updateStatus,
  addLimit,
  getLimits,
  findExchangeBanks,
};