import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/es5/locale/pt';
import theme from '../../public/theme.json';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: theme.light.primary,
        secondary: theme.light.secondary,
        terciary: theme.light.tertiary,
        accent: theme.light.accent,
        info: theme.light.info,
        warning: theme.light.warning,
        error: theme.light.error,
        success: theme.light.success,
        backgroundPrimary: theme.light.background.primary,
        backgroundSecondary: theme.light.background.secondary,
        customerExchangeBankWorkflowStatusCadastroEnviado: theme.light.workflowStatus.customer.cadastroEnviado,
        customerExchangeBankWorkflowStatusCadastroEmAndamento: theme.light.workflowStatus.customer.cadastroEmAndamento,
        customerExchangeBankWorkflowStatusCadastroFinalizado: theme.light.workflowStatus.customer.cadastroFinalizado,
        customerExchangeBankWorkflowStatusCadastroEmAnalise: theme.light.workflowStatus.customer.cadastroEmAnalise,
        customerExchangeBankWorkflowStatusPendenciaDocumental: theme.light.workflowStatus.customer.pendenciaDocumental,
        customerExchangeBankWorkflowStatusEnviadoAoBanco: theme.light.workflowStatus.customer.enviadoAoBanco,
        customerExchangeBankWorkflowStatusPldCompliance: theme.light.workflowStatus.customer.pldCompliance,
        customerExchangeBankWorkflowStatusCadastroAprovado: theme.light.workflowStatus.customer.cadastroAprovado,
        customerExchangeBankWorkflowStatusCadastroRecusado: theme.light.workflowStatus.customer.cadastroRecusado,
        customerExchangeBankWorkflowStatusAtualizacaoCadastral: theme.light.workflowStatus.customer.atualizacaoCadastral,
        customerExchangeBankWorkflowStatusEncerrado: theme.light.workflowStatus.customer.encerrado,
        customerExchangeBankWorkflowStatusPendenciaBanco: theme.light.workflowStatus.customer.pendenciaBanco,
        operationWorkflowStatusEmAnalise: theme.light.workflowStatus.operation.emAnalise,
        operationWorkflowStatusComPendencias: theme.light.workflowStatus.operation.comPendencias,
        operationWorkflowStatusAnalisePendencias: theme.light.workflowStatus.operation.analisePendencias,
        operationWorkflowStatusEmLiquidacao: theme.light.workflowStatus.operation.emLiquidacao,
        operationWorkflowStatusLiquidada: theme.light.workflowStatus.operation.liquidada,
        operationWorkflowStatusCancelada: theme.light.workflowStatus.operation.cancelada,
        colorSell: theme.light.operation.color.sell,
        colorBuy: theme.light.operation.color.buy,
        collapsibleTablePanel: theme.light.table.collapsiblePanel,
        tableCard: theme.light.table.card
      },
      dark: {
        primary: theme.dark.primary,
        secondary: theme.dark.secondary,
        terciary: theme.dark.tertiary,
        accent: theme.dark.accent,
        info: theme.dark.info,
        warning: theme.dark.warning,
        error: theme.dark.error,
        success: theme.dark.success,
        backgroundPrimary: theme.dark.background.primary,
        backgroundSecondary: theme.dark.background.secondary,
        customerExchangeBankWorkflowStatusCadastroEnviado: theme.dark.workflowStatus.customer.cadastroEnviado,
        customerExchangeBankWorkflowStatusCadastroEmAndamento: theme.dark.workflowStatus.customer.cadastroEmAndamento,
        customerExchangeBankWorkflowStatusCadastroFinalizado: theme.dark.workflowStatus.customer.cadastroFinalizado,
        customerExchangeBankWorkflowStatusCadastroEmAnalise: theme.dark.workflowStatus.customer.cadastroEmAnalise,
        customerExchangeBankWorkflowStatusPendenciaDocumental: theme.dark.workflowStatus.customer.pendenciaDocumental,
        customerExchangeBankWorkflowStatusEnviadoAoBanco: theme.dark.workflowStatus.customer.enviadoAoBanco,
        customerExchangeBankWorkflowStatusPldCompliance: theme.dark.workflowStatus.customer.pldCompliance,
        customerExchangeBankWorkflowStatusCadastroAprovado: theme.dark.workflowStatus.customer.cadastroAprovado,
        customerExchangeBankWorkflowStatusCadastroRecusado: theme.dark.workflowStatus.customer.cadastroRecusado,
        customerExchangeBankWorkflowStatusAtualizacaoCadastral: theme.dark.workflowStatus.customer.atualizacaoCadastral,
        customerExchangeBankWorkflowStatusEncerrado: theme.dark.workflowStatus.customer.encerrado,
        customerExchangeBankWorkflowStatusPendenciaBanco: theme.dark.workflowStatus.customer.pendenciaBanco,
        operationWorkflowStatusEmAnalise: theme.dark.workflowStatus.operation.emAnalise,
        operationWorkflowStatusComPendencias: theme.dark.workflowStatus.operation.comPendencias,
        operationWorkflowStatusAnalisePendencias: theme.dark.workflowStatus.operation.analisePendencias,
        operationWorkflowStatusEmLiquidacao: theme.dark.workflowStatus.operation.emLiquidacao,
        operationWorkflowStatusLiquidada: theme.dark.workflowStatus.operation.liquidada,
        operationWorkflowStatusCancelada: theme.dark.workflowStatus.operation.cancelada,
        colorSell: theme.dark.operation.color.sell,
        colorBuy: theme.dark.operation.color.buy,
        collapsibleTablePanel: theme.dark.table.collapsiblePanel,
        tableCard: theme.dark.table.card
      },
    },
  },
});