
function get(exchangeBank, complementaryInfo) {
  return [
    {
      name: "Banco",
      children: [
        {
          name: "Código",
          attribute: "Codigo_Banco",
          title: "Código do banco",
          inline: true,
          content: () => {
            return exchangeBank?.id || '';
          }
        },
        {
          name: "Razão social",
          attribute: "Razao_Social_Banco",
          title: "Razão social do banco",
          inline: true,
          content: () => {
            return exchangeBank?.companyName || '';
          }
        },
        {
          name: "Nome fantasia",
          attribute: "Nome_Fantasia_Banco",
          title: "Nome fantasia do banco",
          inline: true,
          content: () => {
            return exchangeBank?.fantasyName || '';
          }
        },
        {
          name: "CNPJ",
          attribute: "CNPJ_Banco",
          title: "CNPJ do banco ",
          inline: true,
          content: () => {
            return exchangeBank?.cnpj || '';
          }
        },
        {
          name: "Informação complementar do banco",
          attribute: "Informacao_Complementar_Banco",
          title: "Informação complementar - banco",
          inline: true,
          content: () => {
            return complementaryInfo || '';
          }
        },

      ]
    }
  ]
}
export default { get }