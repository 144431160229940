const CHART_COLORS = [
  '#E91E63',
  '#03A9F4',
  '#9C27B0',
  '#2196F3',
  '#00BCD4',
  '#4CAF50',
  '#673AB7',
  '#009688',
  '#FDD835',
  '#9E9E9E',
  '#3F51B5',
  '#607D8B'
];

const ICON_TYPE = {
  PERCENT: {
    ICON: 'fas fa-percent',
    COLOR: '#FFC107'
  },
  CURRENCY: {
    ICON: 'fas fa-dollar-sign',
    COLOR: '#4CAF50'
  },
  INTEGER: {
    ICON: 'fas fa-hashtag',
    COLOR: '#2196f3'
  }
};

export { CHART_COLORS, ICON_TYPE };
