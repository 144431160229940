<template>
  <v-row no-gutters>
    <v-col cols="12">
      <img
        :src="isDark ? logotypeDark : logotypeLight"
        :style="logoClass"
      >
    </v-col>
  </v-row>
</template>

<script>
// Mixins
import mixinGeral from "@/mixin/mixin-geral";

export default {
  name: 'LogoType',

  mixins: [mixinGeral],

  props: {
    maxHeight: {
      type: String,
      default: '200px'
    },
    maxWidth: {
      type: String,
      default: '200px'
    },
  },

  computed: {
    logoClass() {
      return {
        maxHeight: this.maxHeight,
        maxWidth: this.maxWidth
      };
    }
  }
};
</script>