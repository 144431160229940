import theme from '../../public/theme.json';

const mixinGeral = {
  data() {
    return {
      logotypeLight: theme.light.assets.logo || '',
      logotypeDark: theme.dark.assets.logo || '',
    };
  },
  computed: {
    isDark() {
      return (this.$vuetify.theme.dark)
      ? true
      : false;
    }
  },
};

export default mixinGeral;