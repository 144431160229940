<template>
  <div>
    <div>
      <TemplateConfiguration
        v-if="configurationMenu.group == pages.EMAIL.TEMPLATE.VALUE"
        v-model="this.configurationMenu"
      />

      <SMTPConfiguration
        v-if="configurationMenu.group == pages.EMAIL.SMTP.VALUE"
        v-model="this.configurationMenu"
      />
    </div>
    <div>
      <!-- <IntegrationConfiguration /> -->
    </div>
  </div>
</template>

<script>
// Components
import SMTPConfiguration from './SMTPConfiguration.vue';
import TemplateConfiguration from './TemplateConfiguration.vue';
import { PAGES_MENU_CONFIGURATION } from '@/constants/configurations-constants';
import ConfigurationMenuModel from '@/model/generic/configuration-menu-model';
// Models
export default {
  name: 'PageConfiguration',
  components: {
    // IntegrationConfiguration,
    SMTPConfiguration,
    TemplateConfiguration,
  },
  model: {
    prop: "configurationMenuProp",
    event: "onChange",
  },
  props: {
    configurationMenuProp: {
      type: Object,
      required: false,
      default: () => { }
    }
  },
  data() {
    return {
      configurationMenu: new ConfigurationMenuModel(),
      pages: PAGES_MENU_CONFIGURATION
    };
  },
  mounted() {
    this.configurationMenu = this.configurationMenuProp;
  },
  watch: {
    configurationMenuProp(value) {
      this.configurationMenu = value;
    },
  },

};
</script>

<style lang="scss" scoped></style>