<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="button"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        md="12"
      >
        <h1 class="titulo">Controle de Corban</h1>
        <v-divider />
      </v-col>

      <v-col
        cols="12"
        md="9"
        sm="8"
      >
        <v-btn
          class="btn-primary ml-0"
          text
          @click="addCorrespondent"
        >
          <i class="far fa-plus" />
          Adicionar
        </v-btn>
        <v-btn
          class="btn-secondary ml-2"
          text
          @click="openModalPreForm"
        >
          <i class="far fa-paper-plane" />
          Enviar pré-cadastro
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="4"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        />
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <v-card class="card-cambio">
          <v-data-table
            :headers="columns"
            :items="correspondents"
            :search="search"
            sort-by="id"
            sort-desc
            :items-per-page="10"
          >
            <template #[`item.id`]="{ item }">
              <td width="100px">
                {{ item.id }}
              </td>
            </template>
            <template #[`item.name`]="{ item }">
              {{ item.name }}
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <span
                    v-if="item.externalId"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="fas pl-1 fa-tag tag-external-id" />
                  </span>
                </template>
                Corban importado com ID externo {{ item.externalId }}
              </v-tooltip>
            </template>
            <template #[`item.createdAt`]="{ item }">
              {{ item.createdAt | date }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    icon
                    text
                    @click="editCustomer(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="far fa-pencil" />
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      width="500"
      v-model="modalPreForm"
    >
      <v-card>
        <v-card-title>
          <h3>Novo Corban</h3>
        </v-card-title>
        <v-divider class="mt-3 pb-3" />
        <v-card-text class="pa-5">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                validation
                v-model="preFormValid"
                ref="preForm"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="correspondent.name"
                      label="Nome"
                      autocomplete="off"
                      type="text"
                      dense
                      :rules="[validationIsRequiredField]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <MailField
                      v-model="correspondent.accessEmail"
                      label="E-mail"
                      :outlined="false"
                      :rules="[validationIsRequiredField]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-autocomplete
                      label="Tipo de Pessoa"
                      :items="listOfCorrespondentPersonTypes"
                      item-text="description"
                      item-value="id"
                      v-model="correspondent.personType"
                      return-object
                      clearable
                      dense
                      v-disabled-icon-focus
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="btn-tertiary ma-2"
            @click="closeModalPreForm"
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            class="btn-secondary ma-2"
            :disabled="!preFormValid"
            @click="sendPreForm"
          >
            Enviar por email
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// Apis
import apiCorrespondentPersonType from "@/api/correspondent/correspondent-person-type-api";
import apiCorrespondent from "@/api/correspondent/correspondent-api";

// Models
import CorrespondentModel from "@/model/correspondent-model";
import CorrespondentPersonTypeModel from "@/model/correspondent-person-type-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinValidationRules from "@/mixin/mixin-validation-rules";

// Components
import MailField from "@/components/comum/MailField";

// Utils
import dateUtils from "../../../../common/utils/date";
import utilsStorage from "@/utils/storage";

// Tracking
import mixpanel from "mixpanel-browser";


export default {
  name: "CorrespondentsControl",
  mixins: [
    mixinMessage,
    mixinValidationRules,
  ],
  components: {
    MailField,
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
  },
  data() {
    return {
      loading: true,
      search: "",
      modalPreForm: false,
      user: utilsStorage.getUserOnStorage(),
      correspondent: new CorrespondentModel(),
      correspondents: [],
      columns: [
        {
          text: "Código",
          value: "id",
          align: "left",
          sortable: true,
        },
        { text: "Nome", value: "name", align: "left", sortable: true },
        {
          text: "Data de cadastro",
          value: "createdAt",
          align: "center",
          sortable: true,
        },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      preFormValid: false,
      listOfCorrespondentPersonTypes: [],
    };
  },
  mounted() {
    this.getCorrespondentsList();
    this.getCorrespondentPersonType();
    mixpanel.track("page_view", {
      name_of_page_viewed: "correspondents_control",
      user_id: this.user.id,
    });
  },

  computed: {},

  methods: {
    getCorrespondentsList() {
      apiCorrespondent
        .findIndicators()
        .then((response) => {
          this.correspondents = response.data.map((c) => new CorrespondentModel(c));
          this.clearInputs();
          this.loading = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    getCorrespondentPersonType() {
      apiCorrespondentPersonType
        .findAll()
        .then((response) => {
          this.listOfCorrespondentPersonTypes = response.data.map((c) => new CorrespondentPersonTypeModel(c));
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    addCorrespondent() {
      this.$router.push({
        name: "FormCreateCorrespondent",
      });
    },
    async openModalPreForm() {
      this.correspondent = new CorrespondentModel();
      this.$refs.preForm && (await this.$refs.preForm.reset());
      this.modalPreForm = true;
    },

    closeModalPreForm() {
      this.modalPreForm = false;
    },

    editCustomer(correspondent) {
      mixpanel.track("click", {
        button_name: "edit_correspondent",
      });
      this.$router
        .push({
          name: "FormEditCorrespondent",
          params: {
            id: correspondent.id,
          },
        })
        .catch(() => { });
    },

    clearInputs() {
      this.correspondent = new CorrespondentModel();
    },

    updateListCustomer(correspondent, deleteFromList) {
      var index = this.correspondents
        .map((u) => parseInt(u.id))
        .indexOf(parseInt(correspondent.id));
      !deleteFromList
        ? this.correspondents.splice(index, 1, correspondent)
        : this.correspondents.splice(index, 1);
    },

    sendPreForm() {
      mixpanel.track("click", {
        btn_name: "pre_register_correspondent",
      });

      this.correspondent.sendExternalLink = true;
      apiCorrespondent
        .add(this.correspondent)
        .then((response) => {
          let correspondent = new CorrespondentModel(response.data);
          this.correspondents.unshift(correspondent);
          this.sendMessage("Cliente salvo com sucesso!", "success");
          this.closeModalPreForm();
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  },
};
</script>

<style scoped></style>
