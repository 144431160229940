import axios from 'axios';
const URL_IBGE = process.env.VUE_APP_IBGE;

function getState() {
  return new Promise((resolve, reject) => {
    axios.get(`${URL_IBGE}/api/v1/localidades/estados`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function getCity(selectUF) {
  return new Promise((resolve, reject) => {
    axios.get(`${URL_IBGE}/api/v1/localidades/estados/${selectUF}/municipios`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function getCnae() {
  return new Promise((resolve, reject) => {
    axios.get(`${URL_IBGE}/api/v2/cnae/classes/`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function getCnaeByClass(cnaeClass) {
  return new Promise((resolve, reject) => {
    axios.get(`${URL_IBGE}/api/v2/cnae/classes/${cnaeClass}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function getCountry() {
  return new Promise((resolve, reject) => {
    axios.get(`${URL_IBGE}/api/v1/paises?lang=PT/`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  getState,
  getCity,
  getCnae,
  getCnaeByClass,
  getCountry
};
