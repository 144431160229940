import api from '../api';

function add(agreement, natureId) {
  agreement = agreement || {};

  return new Promise((resolve, reject) => {
    return api.post(`/operation-natures/${natureId}/agreements`, agreement)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(agreement, natureId) {
  agreement = agreement || {};

  return new Promise((resolve, reject) => {
    return api.put(`/operation-natures/${natureId}/agreements/${agreement.id}`, agreement)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findByNature(natureId) {
  return new Promise((resolve, reject) => {
    return api.get(`/operation-natures/${natureId}/agreements`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(agreementId, natureId) {
  agreementId = agreementId || {};

  return new Promise((resolve, reject) => {
    return api.get(`/operation-natures/${natureId}/agreements/${agreementId}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findByNature,
  findId,
  add,
  update,
};