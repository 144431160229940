<template>
  <div>
    <v-row
      v-for="(item, index) in customer.directorsProxies"
      :key="index"
    >
      <v-col
        class="py-0"
        cols="12"
        md="5"
      >
        <v-text-field
          outlined
          dense
          label="Nome Completo"
          v-model="item.name"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <CpfOrCnpjField
          v-model="item.cpfCnpj"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="4"
      >
        <v-text-field
          outlined
          dense
          label="Cargo"
          v-model="item.position"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <Datepicker
          v-model="item.termStartDate"
          label="Dt. inicial do mandato"
          v-disabled-icon-focus
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <Datepicker
          v-model="item.termEndDate"
          label="Data final do mandato"
          v-disabled-icon-focus
        />
      </v-col>
      <v-col
        cols="12"
        md="1"
        v-if="customer.directorsProxies.length >= 2"
      >
        <v-btn
          icon
          class="float-right"
          @click="removeDirectors(index)"
          text
        >
          <i class="far fa-trash" />
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="py-0">
      <v-col class="pt-5">
        <v-btn
          class="btn-secondary float-right"
          @click="insertDirectors"
          text
        >
          <i class="far fa-plus" /> Adicionar outro
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Components
import CpfOrCnpjField from "@/components/comum/CpfOrCnpjField.vue";
import Datepicker from "@/components/comum/Datepicker.vue";

// Model
import CustomerDirectorProxyModel from "@/model/customer-director-proxy-model";

export default {
  name: "DirectorsProxies",
  components: { CpfOrCnpjField, Datepicker },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  data() {
    return {
      customer: this.customerProp,
    };
  },
  methods: {
    insertDirectors() {
      this.customer.directorsProxies.push(new CustomerDirectorProxyModel());
    },
    removeDirectors(index) {
      if (this.customer.directorsProxies.length > 1) {
        this.customer.directorsProxies.splice(index, 1);
      }
    },
  },
  mounted() {
    if (this.customer.directorsProxies.length == 0) {
      this.customer.directorsProxies = [new CustomerDirectorProxyModel()];
    }
  },
};
</script>

<style>
</style>