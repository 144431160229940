import api from '../api';

function findByAgent(agentId) {
  return new Promise((resolve, reject) => {
    return api.get(`/agents/${agentId}/commission-accounts`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findRelated(agentId) {
  return new Promise((resolve, reject) => {
    return api.get(`/agents/${agentId}/commission-accounts/related`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findByAgent,
  findRelated,
};