import DashboardCustomerModel from "./dashboard-customer-model";
import DashboardExchangeBankModel from "./dashboard-exchange-bank-model";
import Workflow from '../../../../common/models/Workflow';

export default class DashboardCustomerExchangeBankModel {
  constructor(obj) {
    obj = obj || {};
    this.customer = new DashboardCustomerModel(obj.customer);
    this.bank = new DashboardExchangeBankModel(obj.bank);
    this.status = new Workflow(obj.status);
  }
}
