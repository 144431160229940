<template>
  <div>
    <h1>Componente para configurar SMTP</h1>
  </div>
</template>

<script>
import { PAGES_MENU_CONFIGURATION } from '@/constants/configurations-constants';
import ConfigurationMenuModel from '@/model/generic/configuration-menu-model';

export default {
  name: 'SMTPConfiguration',
  model: {
    prop: "configurationMenuProp",
    event: "onChange",
  },
  props: {
    configurationMenuProp: {
      type: Object,
      required: false,
      default: () => new ConfigurationMenuModel()
    }
  },
  data() {
    return {
      configurationMenu: new ConfigurationMenuModel(), // criando configuration manodel
      pages: PAGES_MENU_CONFIGURATION
    };
  },
  mounted() {
    this.configurationMenu = this.configurationMenuProp;
  },
  watch: {
    configurationMenuProp(value) {
      this.configurationMenu = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>