export default class OperationCustomerAccountModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.internationalAccount = !!obj.internationalAccount;
    this.bank = obj.bank;
    this.name = obj.name;
    this.agency = obj.agency;
    this.account = obj.account;
    this.digit = obj.digit;
    this.pix = obj.pix;
    this.swiftCodeBIC = obj.swiftCodeBIC;
    this.numberIBAN = obj.numberIBAN;
    this.ownerName = obj.ownerName;
    this.tabNumberRouting = obj.tabNumberRouting;
    this.accountName = obj.accountName;
    this.intermediary = obj.intermediary;
    this.intermediaryCountry = obj.intermediaryCountry;
    this.intermediaryBankName = obj.intermediaryBankName;
    this.intermediaryAbaSwiftCode = obj.intermediaryAbaSwiftCode;
    this.forFurtherCredit = obj.forFurtherCredit;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}