import OperationNatureModel from './operation-nature-model';
import ExchangeBankModel from './exchange-bank-model';
import ExchangeBankTypeModel from './exchange-bank-type-model';
import CustomerLimitModel from './customer-limit-model';
import Workflow from '../../../common/models/Workflow';
import { CUSTOMER_EXCHANGE_BANK_WORKFLOW } from "../../../common/workflows/customerExchangeBankWorkflow";

export default class CustomerExchangeBankModel {
  constructor(obj) {
    obj = obj || {};

    let operationNatures = obj.operationNatures?.map(o => new OperationNatureModel(o));

    this.id = obj.id;
    this.bank = new ExchangeBankModel(obj.bank);
    this.exchangeType = new ExchangeBankTypeModel(obj.exchangeType);
    this.operationNatures = operationNatures?.length ? operationNatures : [];
    this.limit = new CustomerLimitModel(obj.limit);
    this.status = obj.status?.id ? new Workflow(obj.status) : new Workflow(CUSTOMER_EXCHANGE_BANK_WORKFLOW.ENVIADO_AO_BANCO);
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}