import AccessModel from './access-model';

export default class AccessProfileModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.description = obj.description;
    this.observation = obj.observation;
    this.access = obj.access?.map((a) => new AccessModel(a));
  }
}
