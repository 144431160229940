import AccessModel from './access-model';
import { ACCESS_VALUES } from '../../../../common/constants/global';

export default class GroupAccessModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.code = obj.code;
    this.action = obj.action;
    this.originId = obj.originId;
    this.children = obj.children?.map((c) => new AccessModel(c)) || [];
    this.value = obj.value || ACCESS_VALUES.ENABLED;

  }
}
