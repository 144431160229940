
export default class Pagination {
  constructor(obj) {
    obj = obj || {};

    this.page = obj.page || 1;
    this.quantity = obj.quantity;
    this.sortBy = obj.sortBy;
    this.sortDesc = obj.sortDesc == 'true' || obj.sortDesc == true;
  }

  getPaginationQuery() {
    if (!this.quantity || this.quantity < 0) {
      return '';
    }
  
    return `
      OFFSET ${(this.page - 1) * this.quantity} ROWS FETCH NEXT ${this.quantity} ROWS ONLY
    `;
  }

  getOrderQuery() {
    return `
      ORDER BY ${this.sortBy || 'id'} ${this.sortDesc ? 'DESC' : 'ASC'}
    `;
  }
}
