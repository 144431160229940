import { render, staticRenderFns } from "./EmptyTable.vue?vue&type=template&id=7bd87f56&scoped=true"
import script from "./EmptyTable.vue?vue&type=script&lang=js"
export * from "./EmptyTable.vue?vue&type=script&lang=js"
import style0 from "./EmptyTable.vue?vue&type=style&index=0&id=7bd87f56&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bd87f56",
  null
  
)

export default component.exports