<template>
  <div class="py-2">
    <PFOnlyCompleteRemittanceFields
      v-if="isCompleteRemittance"
      v-model="customerContactData"
    />

    <PFExchangeBasicCompleteRemittanceFields
      v-if="isExchangeOrBasicOrCompleteRemittance"
      v-model="customerContactData"
    />
  </div>
</template>

<script>
// Components
import PFExchangeBasicCompleteRemittanceFields from "./PFExchangeBasicCompleteRemittanceFields.vue";
import PFOnlyCompleteRemittanceFields from "./PFOnlyCompleteRemittanceFields.vue";

// Constants
import { REGISTER_TYPE } from "@/constants/general-constants";

export default {
  name: "PFCustomerContact",
  components: {
    PFExchangeBasicCompleteRemittanceFields,
    PFOnlyCompleteRemittanceFields,
  },
  model: {
    prop: "model",
    event: "onChange",
  },
  props: {
    model: {
      type: Object,
    },
  },
  watch: {
    customerContactData() {
      this.$emit("onChange", this.customerContactData);
    },
  },
  computed: {
    isExchangeOrBasicOrCompleteRemittance() {
      return this.customerContactData &&
        this.customerContactData.registerType.id !=
          REGISTER_TYPE.LEGAL_PERSON.id
        ? true
        : false;
    },
    isCompleteRemittance() {
      return this.customerContactData.registerType.id ==
        REGISTER_TYPE.COMPLETE_REMITTANCE.id
        ? true
        : false;
    },
  },
  data() {
    return {
      customerContactData: this.model,
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
    };
  },
};
</script>

<style>
</style>