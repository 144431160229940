import { Buffer } from 'buffer';

function removeCellphoneMask(cellphone) {
    if (!cellphone) {
      return 0;
    }

    return cellphone
      .toString()
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "")
      .replaceAll(" ", "")
      .trim();
}

/**
 * Returns the JSON payload from a JWT Token. IMPORTANT: this does not validate the signature!
 * @param {String} token The JWT token
 * @returns {Object} The payload
 */
function parseJwt(token) {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}

export default {
    removeCellphoneMask,
    parseJwt,
};
