import CurrencyModel from './currency-model';

export default class OperationStatementModel {
  constructor(obj) {
    obj = obj || {};

    this.date = obj.date; // Operation is effectively operation date and limit is date of creation.

    // Operations params
    this.amount = obj.amount;
    this.currency = new CurrencyModel(obj.currency);
    this.operationId = obj.operationId;
    this.totalValueIn = obj.totalValueIn;
    this.totalValueOut = obj.totalValueOut;

    // Limit params
    this.isInOut = obj.isInOut;
    this.value = obj.value;
    this.valueIn = obj.valueIn;
    this.valueOut = obj.valueOut;
  }
}