<template>
  <v-card class="table-empty box card-cambio text-center">
    <div class="content">
      <div class="icon">
        <i :class="icon" />
      </div>
      <span class="title-table-empty">
        {{ title }}
      </span>
      <span
        class="subtitle-table-empty"
        v-if="subtitle"
      >{{ subtitle }}</span>
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'EmptyTable',
  props: {
    title: {
      default: 'Não há registros emitidos',
      type: String
    },
    icon: {
      default: '',
      type: String
    },
    subtitle: {
      default: '',
      type: String
    },
    nameOfButton: {
      default: '',
      type: String
    },
    callbackButton: {
      default: () => {},
      type: Function
    }
  }
};
</script>

<style lang="scss" scoped>
.content{
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.icon>i {
  color: var(--v-primary-base);
  display: block;
  font-size: 3rem;
}

.title-table-empty {
  display: block;
  font-size: 1.4em;
  color: var(--v-primary-base);
  padding: 20px 0;
}

.subtitle-table-empty {
  text-align: center;
}
</style>