<template>
  <v-main :style="backgroundMainStyle">
    <div class="login">
      <v-card
        :loading="loading"
        class="box-login"
        elevation="10"
      >
        <form
          class="form-login"
          autocomplete="off"
        >
          <LogoType />
          <!-- Login Screen -->
          <v-col
            v-if="screenLogin"
            cols="12"
            class="m-0 p-0"
          >
            <v-text-field
              data-test="ipt-email"
              autofocus
              color="primary"
              label="Email"
              v-model="user.email"
            />
            <v-text-field
              data-test="ipt-senha"
              @keyup.enter="login"
              label="Senha"
              v-model="user.password"
              :append-icon="value ? 'visibility_off' : 'visibility'"
              @click:append="() => (value = !value)"
              :type="value ? 'password' : 'text'"
            />
            <v-spacer class="py-1" />

            <v-btn
              data-test="btn-entrar"
              @click="login"
              text
              class="btn-primary btn-block"
            >
              Entrar
            </v-btn>
            <div
              data-test="link-esqueci-minha-senha"
              class="forgot-password pt-2"
            >
              <a @click="_switchToForgotPassword()">Esqueci minha senha</a>
            </div>
          </v-col>

          <!-- Forgot my password screen -->
          <v-col
            v-if="screenForgotPassword"
            cols="12"
            class="m-0 p-0"
          >
            <h3
              data-test="h3-titulo-recuperar-senha"
              class="pb-3"
            >
              Preencha seu e-mail para enviarmos o código para redefinir sua
              senha
            </h3>
            <v-text-field
              color="primary"
              label="Email"
              v-model="user.email"
            />
            <div class="forgot-password pt-2">
              <v-btn
                data-test="btn-enviar-codigo-confirmacao"
                @click="sendEmailCode()"
                class="btn-primary"
                text
              >
                Enviar
                código de confirmação
              </v-btn>
              <v-spacer class="py-1" />
              <v-btn
                data-test="btn-voltar-tela-login"
                @click="_back()"
                class="btn-tertiary"
                text
              >
                Voltar
              </v-btn>
            </div>
          </v-col>

          <!-- Confirmation code screen -->
          <v-col
            v-if="screenConfirmationCode"
            cols="12"
            class="m-0 p-0"
          >
            <h3 class="pb-3">
              Digite o código recebido no e-mail {{ this.user.email }}
            </h3>
            <v-text-field
              color="primary"
              label="Código de Confirmação"
              v-model="user.code"
            />
            <v-btn
              @click="validateCode()"
              class="btn-primary btn-block"
              text
            >
              Confirmar
            </v-btn>

            <v-spacer class="py-1" />

            <v-btn
              @click="_back()"
              class="btn-tertiary"
              text
            >
              Voltar
            </v-btn>
          </v-col>

          <!-- New password screen -->
          <v-col
            v-if="screenNewPassword"
            cols="12"
            class="m-0 p-0"
          >
            <h3 class="pb-3">Escolha sua nova senha.</h3>
            <v-text-field
              label="Nova Senha"
              required
              :append-icon="value ? 'visibility_off' : 'visibility'"
              @click:append="() => (value = !value)"
              :type="value ? 'password' : 'text'"
              v-model="user.newPassword"
              autofocus
            />
            <v-text-field
              label="Confirme sua senha"
              required
              :rules="[(v) => !!v || 'Confirme a nova senha']"
              :append-icon="value ? 'visibility_off' : 'visibility'"
              @click:append="() => (value = !value)"
              :type="value ? 'password' : 'text'"
              v-model="user.newPasswordConfirmation"
            />

            <v-btn
              @click="changePassword()"
              class="btn-primary btn-block"
              text
            >
              Salvar
            </v-btn>

            <v-spacer class="py-1" />
          </v-col>
        </form>
      </v-card>
    </div>
    <div class="botao-dark">
      <v-btn
        icon
        id="mode-switcher"
        @click="changeTheme"
      >
        <i
          v-if="$vuetify.theme.dark"
          class="far fa-sun"
        />
        <i
          v-else
          class="far fa-moon"
        />
      </v-btn>
    </div>
  </v-main>
</template>

<script>
// Apis
import authApi from "@/api/auth/auth-api";

// Utils
import utilsStorage from "@/utils/storage";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinGeral from "@/mixin/mixin-geral";

// Models
import UserModel from "@/model/user-model";

// Tracking
import mixpanel from "mixpanel-browser";

// Theme
import theme from '../../public/theme.json';

// Components
import LogoType from '@/components/comum/LogoType.vue';

export default {
  name: "Login",
  mixins: [mixinMessage, mixinGeral],
  components: { LogoType },
  data() {
    return {
      loading: false,
      user: new UserModel(),
      value: "password",
      screenLogin: true,
      screenForgotPassword: false,
      screenConfirmationCode: false,
      screenNewPassword: false,
      backgroundLight: theme.light.assets.loginBackground,
      backgroundDark: theme.dark.assets.loginBackground,
    };
  },
  computed: {
    backgroundMainStyle() {
      return {
        background: `url(${this.$vuetify.theme.dark ? this.backgroundDark : this.backgroundLight})`,
        backgroundSize: "cover",
      };
    },
  },

  mounted() {
    mixpanel.track("page_view", { name_of_page_viewed: "login" });
    this.$eventBus.$emit('token-changed');
  },

  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      utilsStorage.setThemeSettings(this.$vuetify.theme.dark);
    },
    _back() {
      this.screenLogin = true;
      this.screenForgotPassword = false;
      this.screenConfirmationCode = false;
      this.screenNewPassword = false;
      mixpanel.track("click", {
        name_of_page_viewed: "login",
        button_name: "go_back",
      });
    },

    _switchToForgotPassword() {
      mixpanel.track("click", {
        name_of_page_viewed: "login",
        button_name: "forgot_password",
      });
      this.screenLogin = false;
      this.screenForgotPassword = true;
      this.screenConfirmationCode = false;
      this.screenNewPassword = false;
    },

    _swithToConfirmationCode() {
      this.screenLogin = false;
      this.screenForgotPassword = false;
      this.screenConfirmationCode = true;
      this.screenNewPassword = false;
    },

    _switchToNewPassword() {
      this.screenLogin = false;
      this.screenForgotPassword = false;
      this.screenConfirmationCode = false;
      this.screenNewPassword = true;
    },

    sendEmailCode() {
      mixpanel.track("click", {
        name_of_page_viewed: "login",
        button_name: "send_code",
      });
      authApi
        .forgotPassword(this.user.email)
        .then(() => {
          this.sendMessage(
            "Enviamos o código para o e-mail informado",
            "success"
          );
          this._swithToConfirmationCode();
        })
        .catch(() => {
          this.sendMessage("Falha no envio do código por e-mail", "error");
        });
    },

    validateCode() {
      mixpanel.track("click", {
        name_of_page_viewed: "login",
        button_name: "validate_code",
      });
      authApi
        .validateUserByCode(this.user.email, this.user.code)
        .then((resposta) => {
          this.saveInformationsOnLocalStorage(
            resposta.data.user,
            resposta.data.token
          );
          this._switchToNewPassword();
        })
        .catch(() => {
          this.sendMessage("Falha na validação do código", "error");
        });
    },

    changePassword() {
      mixpanel.track("click", {
        name_of_page_viewed: "login",
        button_name: "change_password",
      });
      authApi
        .changePassword(this.user.newPassword)
        .then(() => {
          this.sendMessage("Senha alterada com sucesso", "success");
          this.$router.push({ name: "Dashboard" });
        })
        .catch(() => {
          this.sendMessage(
            "Falha na redefinição de senha, verifique os campos",
            "error"
          );
        });
    },

    login() {
      mixpanel.track("click", {
        name_of_page_viewed: "login",
        button_name: "login",
      });

      if (!this.user.email || !this.user.password) {
        this.sendMessage("Informe o e-mail e senha para acesso.", "error");

        return;
      }

      this.loading = true;
      authApi
        .login(this.user)
        .then((response) => {
          this.loading = false;
          this.initTracking(response);
          mixpanel.track("login");
          this.saveInformationsOnLocalStorage(
            response.data.user,
            response.data.token
          );
          this.$router.push({ name: "Dashboard" });
        })
        .catch(() => {
          this.sendMessage("Usuário ou senha inválidos.", "error");
          utilsStorage.removeAuth();
          this.loading = false;
        });
    },

    initTracking(response) {
      mixpanel.identify(response.data.user.id);
      mixpanel.people.set({
        $id: response.data.user.id,
        $name: response.data.user.name,
        $email: response.data.user.email,
        $first_login: new Date(),
        $profile: response.data.user.profileId,
      });

      return;
    },

    saveInformationsOnLocalStorage(user, token) {
      utilsStorage.saveTokenOnStorage(token);
      utilsStorage.saveUserOnStorage(user);
      this.$eventBus.$emit('token-changed');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-main {
  height: 100vh;
  padding: 90px 0px 0px 56px
}

.box-login {
  width: 350px;
  text-align: center;
  border-radius: 10px;
  margin: auto;
  padding: 10px;
}

.login {
  margin-top: 10%;
  margin-bottom: 10%;
}

.form-login {
  width: 100%;
  padding: 15px;
}

.forgot-password {
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}

.botao-dark {
  display: block;
  left: 10px;
  position: absolute;
  bottom: 10px;
}
</style>