import apiCountries from "@/api/general/country-api";
import CountryModel from "@/model/country-model";
var listOfCountries = [];

function getCountry() {
  apiCountries.findAll().then((response) => {
    listOfCountries = response.data.map(i => new CountryModel(i));
  });
}

getCountry();

function formatCountry(code) {
  if (!code) {
    return '';
  }

  let country = listOfCountries.find(i => i.code == code);

  return country ? country.name : '';
}

export default {
  formatCountry,
};
