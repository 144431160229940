import { MUTATIONS_CONFIGURATIONS } from "./mutations";
import apiConfigurations from "../../../api/generic/configuration-api";

export default {
  updateConfigurationsAPI({ commit }, configurations) {

    apiConfigurations.updateList(configurations)
      .then((response) => {
        //Atualizar sempre todas as configurações.
        commit(MUTATIONS_CONFIGURATIONS.UPDATE_CONFIGURATIONS, response.data);
      })
      .catch((error) => {
        console.log("ERROR WHEN UPDATE CONFIGURATIONS API: ", error);
      });
  },

  updateConfigurationsModel({ commit }, configurations) {
    commit(MUTATIONS_CONFIGURATIONS.UPDATE_CONFIGURATIONS, configurations);
  }
};