export default class CustomerOccupationModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.company = obj.company;
    this.profession = obj.profession;
    this.position = obj.position;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}