<template>
  <v-row v-if="isPF">
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1>PEP - <small>Pessoa politicamente exposta</small></h1>
        <v-divider class="py-3" />
        <PFCustomerPoliticallyExposedPerson v-model="customer" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Constants
import { REGISTER_TYPE } from "@/constants/general-constants.js";
import PFCustomerPoliticallyExposedPerson from "./PF/politicallyExposePerson/PFCustomerPoliticallyExposedPerson.vue";

export default {
  name: "CustomerPoliticallyExposedPerson",
  components: { PFCustomerPoliticallyExposedPerson },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  computed: {
    isPF() {
      return this.customer &&
        this.customer.registerType.id == REGISTER_TYPE.LEGAL_PERSON.id
        ? false
        : true;
    },
  },
  data() {
    return {
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
      customer: this.customerProp,
    };
  },
};
</script>

<style>
</style>