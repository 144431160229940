import DashboardCurrencyModel from "./dashboard-currency-model";
import DashboardCustomerModel from "./dashboard-customer-model";
import DashboardExchangeBankModel from "./dashboard-exchange-bank-model";
import DashboardExchangeBankTypeModel from "./dashboard-exchange-bank-type-model";
import DashboardOperationAdministrativeModel from "./dashboard-operation-administrative-model";

export default class DashboardOperationModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.exchangeType = new DashboardExchangeBankTypeModel(obj.exchangeType);
    this.exchangeBuy = obj.exchangeBuy;
    this.customer = new DashboardCustomerModel(obj.customer);
    this.bank = new DashboardExchangeBankModel(obj.bank);
    this.currency = new DashboardCurrencyModel(obj.currency);
    this.amount = obj.amount && parseFloat(obj.amount);
    this.spread = obj.spread;
    this.administrative = new DashboardOperationAdministrativeModel(obj.administrative);
  }
}
