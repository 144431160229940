<template>
  <span>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              text
              @click="openModalBankStatement(customer)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-file-export" />
            </v-btn>
          </template>
          <span>Extrato de movimentação</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      v-model="modalStatement"
      max-width="75vw"
    >
      <v-card>
        <v-card-title>
          <h3>Extrato de movimentações - {{ this.customer.name }} - {{ this.exchangeBank.bank?.companyName }}</h3>
        </v-card-title>
        <v-divider class="mt-3 pb-3" />
        <v-card-text class="pa-5">
          <v-row>
            <v-col
              cols="12"
              md="2"
              sm="3"
            >
              <Datepicker
                v-model="startDate"
                label="Data Inicial"
                v-disabled-icon-focus
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              sm="3"
            >
              <Datepicker
                v-model="endDate"
                label="Data Final"
                v-disabled-icon-focus
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              sm="2"
            >
              <v-btn
                text
                class="btn-secondary"
                @click="applyFilterByDate"
              >Aplicar filtro</v-btn>
            </v-col>
            <v-spacer />
            <template v-if="exchangeBank.limit.isInOut">
              <v-col
                cols="12"
                md="2"
                sm="6"
              >
                <label>Limite Disponível Entrada</label>
                <h2 class="limit-value">{{ this.exchangeBank.limit.remainingIn | formatLimit(exchangeBank) }}</h2>
              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="6"
              >
                <label>Limite Disponível Saída</label>
                <h2 class="limit-value">{{ this.exchangeBank.limit.remainingOut | formatLimit(exchangeBank) }}</h2>
              </v-col>
            </template>
            <template v-else>
              <v-col
                cols="12"
                md="2"
                sm="6"
              >
                <label>Limite Disponível</label>
                <h2 class="limit-value">{{ this.exchangeBank.limit.remaining | formatLimit(exchangeBank) }}</h2>
              </v-col>
            </template>
          </v-row>
          <v-divider class="py-4" />
          <v-data-table
            dense
            :headers="columnsStatement"
            :items="statementItems"
            sort-by="date"
            no-data-text="Não há informações"
            sort-desc
            :items-per-page="10"
          >
            <template #[`item.date`]="{ item }">
              {{ item.date | date }}
            </template>
            <template #[`item.valueIn`]="{ item }">
              <template v-if="item.isInOut">
                {{ item.valueIn | formatLimit(exchangeBank) }}
              </template>
              <template v-else-if="item.value">
                <v-icon class="hidden-sm-and-down">mdi-arrow-right-bottom</v-icon>
                <i class="fas fa-long-arrow-down fa-lg hidden-md-and-up" />
              </template>
            </template>
            <template #[`item.value`]="{ item }">
              <template v-if="!item.isInOut && item.value">
                {{ item.value | formatLimit(exchangeBank) }}
              </template>
            </template>
            <template #[`item.valueOut`]="{ item }">
              <template v-if="item.isInOut">
                {{ item.valueOut | formatLimit(exchangeBank) }}
              </template>
              <template v-else-if="item.value">
                <v-icon class="hidden-sm-and-down">mdi-arrow-left-bottom</v-icon>
                <i class="fas fa-long-arrow-up fa-lg hidden-md-and-up" />
              </template>
            </template>
            <template #[`item.amount`]="{ item }">
              <template
                v-if="item.amount"
                class="debit"
              >
                {{ item.currency.code }} {{ item.amount | formatNumber }}
              </template>
            </template>

            <template #[`item.totalValueIn`]="{ item }">
              <template
                v-if="item.totalValueIn"
                class="debit"
              >
                {{ item.totalValueIn | formatCurrency }}
              </template>
            </template>
            <template #[`item.totalValueOut`]="{ item }">
              <template
                v-if="item.totalValueOut"
                class="debit"
              >
                {{ item.totalValueOut | formatCurrency }}
              </template>
            </template>
          </v-data-table>
          <v-row>
            <v-col
              cols="12"
              md="3"
              sm="6"
            >
              <label>Movimentação Entrada</label>
              <h2 class="limit-value">{{ totalAmountIn || 0 | formatCurrency() }}</h2>
            </v-col>
            <v-col
              cols="12"
              md="3"
              sm="6"
            >
              <label>Movimentação Saída</label>
              <h2 class="limit-value">{{ totalAmountOut || 0 | formatCurrency() }}</h2>
            </v-col><v-spacer />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="btn-tertiary ma-2"
            @click="closeModalBankStatement"
          >Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
// Apis
import apiCustomers from "@/api/customer/customer-api";
import apiOperations from "@/api/operation/operation-api";

// Models
import CustomerLimitModel from "@/model/customer-limit-model";
import OperationModel from "@/model/operation-model";
import CustomerLimitFiltersModel from "@/model/customer-limit-filters-model";
import OperationFiltersModel from "@/model/operation-filters-model";
import OperationStatementModel from "@/model/operation-statement-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Components
import Datepicker from "@/components/comum/Datepicker.vue";

// Utils
import dateUtils from "../../../../common/utils/date";
import numberUtils from "../../../../common/utils/number";

// Constants

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "CustomerBankStatement",
  mixins: [mixinMessage],
  components: {
    Datepicker
  },
  props: {
    customer: {
      type: Object,
    },
    exchangeBank: {
      type: Object,
    },
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    formatLimit(value, exchangeBank) {
      if (exchangeBank.bank.useUsdLimit) {
        return numberUtils.formatCurrency('USD', value);
      }

      return numberUtils.formatCurrency('BRL', value);
    },
    formatCurrency(value, precison) {
      return numberUtils.formatCurrency('BRL', value, precison);
    },
    formatNumber(value) {
      return new Intl.NumberFormat('pt-BR').format(value);
    },
  },
  data() {
    return {
      startDate: new Date((new Date()).setDate(1)),
      endDate: new Date(),
      modalStatement: false,
      limits: [],
      operations: [],
      columnsStatement: [
        { text: "Data", value: "date", align: "center", sortable: true, },
        { text: "Limite Entrada", value: "valueIn", align: "center", },
        { text: "", value: "value", align: "center", width: "5%" },
        { text: "Limite Saída", value: "valueOut", align: "center", },
        { text: "Valor da Operação ME", value: "amount", align: "right", },
        { text: "Movimentação Entrada", value: "totalValueIn", align: "center", },
        { text: "Movimentação Saída", value: "totalValueOut", align: "center", },
        { text: "Código da Operação", value: "operationId", align: "right", },
        { text: "", value: "actions", align: "right", }
      ],
    };
  },
  mounted() { },

  computed: {

    totalAmountIn() {
      let totalAmountIn = this.statementItems.reduce((total, statement) => {
        return total + +(statement.totalValueIn || 0);
      }, 0);

      return totalAmountIn;
    },

    totalAmountOut() {
      let totalAmountOut = this.statementItems.reduce((total, statement) => {
        return total + +(statement.totalValueOut || 0);
      }, 0);

      return totalAmountOut;
    },

    statementItems() {
      let items = [];

      this.limits.forEach((limit) => {
        let limitItem = new OperationStatementModel({
          ...limit,
          date: limit.createdAt,
        });
        items.push(limitItem);
      });

      this.operations.forEach((operation) => {
        let operationItem = new OperationStatementModel({
          ...operation,
          operationId: operation.id,
          date: operation.effectiveOperationDate,
        });

        if (operation.exchangeBuy) {
          operationItem.totalValueIn = operation.totalValue;
        } else {
          operationItem.totalValueOut = operation.totalValue;
        }

        items.push(operationItem);
      });

      return items;
    },
  },

  watch: {},

  methods: {
    closeModalBankStatement() {
      this.modalStatement = false;
    },

    applyFilterByDate() {
      this.getBankStatement();
    },

    openModalBankStatement() {
      mixpanel.track("page_view", {
        name_of_page_viewed: "customer_bank_statement",
        customer_id: this.customer.id,
        bank_id: this.exchangeBank.bank.id,
      });
      this.getBankStatement();
    },

    getBankStatement() {
      let indexBank = this.customer.exchangeBanks.findIndex((i) => i.bank.id == this.exchangeBank.bank.id);
      let idExchangeBank = this.customer.exchangeBanks[indexBank].bank.id;

      let bankLimitsFilter = new CustomerLimitFiltersModel({
        idCustomer: this.customer.id,
        idExchangeBank: idExchangeBank,
        startDate: this.startDate,
        endDate: this.endDate,
      });

      let operationFilter = new OperationFiltersModel({
        customerId: this.customer.id,
        bankList: [this.exchangeBank.bank.id],
        exchangeTypeId: this.exchangeBank.exchangeType.id,
        idExchangeBank: this.exchangeBank.bank.id,
        startDate: this.startDate,
        endDate: this.endDate,
      });

      apiCustomers
        .getLimits(bankLimitsFilter)
        .then((response) => {
          this.limits = response.data.map((l) => new CustomerLimitModel(l));
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.sendMessage('Não há limites cadastrados para este banco', 'error');
          }
        });

      apiOperations
        .getOperations(operationFilter)
        .then((response) => {
          this.operations = response.data.map((o) => new OperationModel(o));
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.sendMessage('Não há operações realizadas para este banco', 'error');
          }
        });
      this.modalStatement = true;
    },
  },
};
</script>

<style scoped lang="scss">
.limit-value {
  color: var(--v-primary-base);
}

.debit {
  color: red;
}
</style>
