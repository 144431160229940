<template>
  <v-text-field
    outlined
    dense
    :autofocus="autofocus"
    inputmode="decimal"
    color="primary"
    v-model="cpfCnpjData"
    :label="isPJ ? 'CNPJ' : 'CPF'"
    v-mask="isPJ ? '##.###.###/####-##' : '###.###.###-##'"
    :rules="[...rules, validationIsCpfCnpjValid]"
  />
</template>

<script>

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "CpfOrCnpjField",
  mixins: [mixinValidationRules],
  model: {
    prop: "model",
    event: "onChange",
  },
  props: {
    autofocus: {
      type: Boolean,
    },
    model: {
      type: String,
    },
    isPJ: {
      type: Boolean
    },
    rules: { type: Array, required: false, default: () => [] },
  },
  watch: {
    cpfCnpjData() {
      this.$emit("onChange", this.cpfCnpjData);
    },
  },
  data() {
    return {
      cpfCnpjData: this.model,
    };
  },
};
</script>

<style></style>