import api from '../api';

function add(skecth) {
  skecth = skecth || {};

  return new Promise((resolve, reject) => {
    return api.post('/sketches', skecth)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function find(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/sketches/', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(id, sketch) {
  sketch = sketch || {};

  return new Promise((resolve, reject) => {
    return api.put(`/sketches/${id}`, sketch)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.get(`/sketches/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function remove(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.delete(`/sketches/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}


export default {
  add,
  find,
  update,
  findId,
  remove,
};