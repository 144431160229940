import api from '../api';

function trigger(trigger) {
  trigger = trigger || {};

  return new Promise((resolve, reject) => {
    return api.post('/triggers', trigger)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  trigger,
};