<template>
  <v-autocomplete
    :label="label"
    :items="listOfCountries"
    item-text="name"
    item-value="code"
    v-model="countryData"
    clearable
    dense
    outlined
    v-disabled-icon-focus
    :rules="rules"
  />
</template>

<script>
// API
import apiCountries from "@/api/general/country-api";

// Model
import CountryModel from "../../model/country-model";

export default {
  name: "CountryField",
  model: {
    prop: "model",
    event: "onChange",
  },
  watch: {
    countryData() {
      this.$emit("onChange", this.countryData);
    },
  },
  props: {
    model: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    rules: { type: Array, required: false },
  },
  data() {
    return {
      listOfCountries: undefined,
      countryData: this.model,
    };
  },
  mounted() {
    this.getCountry();
  },
  methods: {
    getCountry() {
      apiCountries.findAll().then((response) => {
        this.listOfCountries = response.data.map(i => new CountryModel(i));
      });
    },
  },
};
</script>
