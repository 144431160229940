export default class OperationPaymentAccountModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.bank = obj.bank;
    this.agency = obj.agency;
    this.account = obj.account;
    this.digit = obj.digit;
    this.paymentAccount = obj.paymentAccount;
    this.pix = obj.pix;
  }
}