import api from '../api';



function findAll(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/audits/', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findById(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.get(`/audits/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findByEntity(entityId) {
  return new Promise((resolve, reject) => {
    return api.get(`/audits/entity/${entityId}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  findById,
  findByEntity,
};