import MarriageSystemModel from './marriage-system-model';
import OperationNatureModel from './operation-nature-model';
import FormOfIncorporationModel from './form-of-incorporation-model';
import MaritalStatusModel from './marital-status-model';
export default class CustomerAdditionalModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.newInternationalAccount = obj.newInternationalAccount;
    this.averageMonthlyIncome = obj.averageMonthlyIncome;
    this.assets = obj.assets && parseFloat(obj.assets);
    this.spouseName = obj.spouseName;
    this.spouseCPF = obj.spouseCPF;
    this.maritalStatus = new MaritalStatusModel(obj.maritalStatus);
    this.marriageSystem = new MarriageSystemModel(obj.marriageSystem);
    this.politicallyExposedPerson = obj.politicallyExposedPerson;
    this.importedOrExportedBefore = obj.importedOrExportedBefore;
    this.position = obj.position;
    this.positionCode = obj.positionCode;
    this.practiceStartDate = obj.practiceStartDate;
    this.practiceEndDate = obj.practiceEndDate;
    this.companyPublicAgency = obj.companyPublicAgency;
    this.cnpj = obj.cnpj;
    this.relationshipPublicAgent = obj.relationshipPublicAgent;
    this.relatedName = obj.relatedName;
    this.relatedCPF = obj.relatedCPF;
    this.relatedPosition = obj.relatedPosition;
    this.relationshipType = obj.relationshipType;
    this.operationNature = new OperationNatureModel(obj.operationNature);
    this.annualGrossRevenue = obj.annualGrossRevenue;
    this.integratedSocialCapital = obj.integratedSocialCapital;
    this.nameRegistrationResponsible = obj.nameRegistrationResponsible;
    this.positonRegistrationResponsible = obj.positonRegistrationResponsible;
    this.emailRegistrationResponsible = obj.emailRegistrationResponsible;
    this.cellphoneRegistrationResponsible = obj.cellphoneRegistrationResponsible;
    this.formOfIncorporation = new FormOfIncorporationModel(obj.formOfIncorporation);
    this.sectorActivity = obj.sectorActivity;
    this.mainActivity = obj.mainActivity;
    this.idCNAE = obj.idCNAE;
    this.numberEmployees = obj.numberEmployees;
    this.foundingDate = obj.foundingDate;
    this.lastChangeDate = obj.lastChangeDate;
    this.boardElectionDate = obj.boardElectionDate;
    this.dependentName = obj.dependentName;
    this.dependentKinship = obj.dependentKinship;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}