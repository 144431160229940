<template>
  <div>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        md="6"
      >
        <legend>Exerce ou exerceu cargo ou função relevante?</legend>
        <v-radio-group
          row
          class="py-0"
          v-model="customer.additional.politicallyExposedPerson"
          :rules="[validationIsRequiredRadioBtn]"
        >
          <v-radio
            :value="true"
            :label="'Sim'"
          />
          <v-radio
            :value="false"
            :label="'Não'"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="customer.additional.politicallyExposedPerson">
      <v-col
        class="py-0"
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="customer.additional.position"
          outlined
          dense
          label="Cargo"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="customer.additional.positionCode"
          outlined
          dense
          label="Código do cargo"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <Datepicker
          label="Inicio do exercicio"
          v-model="customer.additional.practiceStartDate"
          v-disabled-icon-focus
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <Datepicker
          label="Fim do exercicio"
          v-model="customer.additional.practiceEndDate"
          v-disabled-icon-focus
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="customer.additional.companyPublicAgency"
          outlined
          dense
          label="Empresa ou orgão público"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <legend>Possui relacionamento com agente público?</legend>
        <v-radio-group
          row
          v-model="customer.additional.relationshipPublicAgent"
          :rules="[validationIsRequiredRadioBtn]"
        >
          <v-radio
            :value="true"
            :label="'Sim'"
          />
          <v-radio
            :value="false"
            :label="'Não'"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="customer.additional.relationshipPublicAgent">
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="customer.additional.relatedName"
          outlined
          dense
          label="Nome completo do relacionado"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <CpfOrCnpjField
          v-model="customer.additional.relatedCPF"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="customer.additional.relatedPosition"
          outlined
          dense
          label="Cargo ou função"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="customer.additional.relationshipType"
          outlined
          dense
          label="Tipo de relacionamento / Relação"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Datepicker from "@/components/comum/Datepicker";
import CpfOrCnpjField from "@/components/comum/CpfOrCnpjField";
import { REGISTER_TYPE } from "@/constants/general-constants.js";

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "PFCustomerPoliticallyExposedPerson",
  mixins: [ mixinValidationRules ],
  components: { Datepicker, CpfOrCnpjField },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  data() {
    return {
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
      customer: this.customerProp,
    };
  },
};
</script>

<style>
</style>