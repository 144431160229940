const mixinNestedFormValidation = {
  data() {
    return {
      
    };
  },
  methods: {
    // Simulates the process used in vuetify 1 for nested components form validation
    // Check https://github.com/vuetifyjs/vuetify/issues/4900
    reassignFormInputs(form) {
      const inputs = [];
      // Copied from VForm's previous life* which had a getInputs() function
      const search = (children, depth = 0) => {
        for (let index = 0; index < children.length; index++) {
          const child = children[index];

          if (child.errorBucket !== undefined) inputs.push(child);
          else search(child.$children, depth + 1);
        }

        if (depth === 0) return inputs;
      };

      search(form.$children);
      form.inputs = inputs;
    },
  }
};

export default mixinNestedFormValidation;