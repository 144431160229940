<template>
  <v-row>
    <div>
      <ConfirmationWindow
        v-model="openConfirmationWindow"
        :callback-primary="confirmationWindowCallback"
        :message="confimationWindowMessage"
      />
    </div>

    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1>Limites</h1>
        <v-divider class="py-3 mt-3" />
        <v-row>
          <v-col
            cols="12"
            md="10"
          >
            <v-btn
              class="btn-primary ml-0"
              text
              @click="openAddExchangeBankModal"
            >
              <i class="far fa-plus" />
              Adicionar Banco
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            lg="3"
            sm="6"
            offset-lg="9"
            offset-sm="6"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-card class="card-cambio">
              <v-data-table
                :headers="columns"
                :items="customer.exchangeBanks"
                :search="search"
                sort-by="id"
                sort-desc
                :items-per-page="10"
              >
                <template #[`item.operationNatures`]="{ item }">
                  <template v-for="(nature, i) in item.operationNatures">
                    <v-chip
                      small
                      label
                      :key="i"
                      class="ma-1"
                    >
                      {{ nature.exibitionName }}
                    </v-chip>
                  </template>
                </template>

                <template #[`item.limitIn`]="{ item }">
                  <template v-if="item.limit.isInOut">
                    {{ item | formatLimit(item.limit.remainingIn) }}
                  </template>
                  <template v-else>
                    <v-icon class="hidden-sm-and-down">mdi-arrow-right-bottom</v-icon>
                    <i class="fas fa-long-arrow-down fa-lg hidden-md-and-up" />
                  </template>
                </template>

                <template #[`item.limit.remaining`]="{ item }">
                  <template v-if="!item.limit.isInOut">
                    {{ item | formatLimit(item.limit.remaining) }}
                  </template>
                </template>

                <template #[`item.limitOut`]="{ item }">
                  <template v-if="item.limit.isInOut">
                    {{ item | formatLimit(item.limit.remainingOut) }}
                  </template>
                  <template v-else>
                    <v-icon class="hidden-sm-and-down">mdi-arrow-left-bottom</v-icon>
                    <i class="fas fa-long-arrow-up fa-lg hidden-md-and-up" />
                  </template>
                </template>

                <template #[`item.limit.expireAt`]="{ item }">
                  {{ item.limit.expireAt | date }}
                </template>

                <template #[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        text
                        @click="editExchangeBankModal(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="far fa-pencil" />
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        text
                        @click="openAddLimitModal(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="far fa-hand-holding-usd" />
                      </v-btn>
                    </template>
                    <span>Alterar Limite</span>
                  </v-tooltip>
                  <CustomerBankStatement
                    :customer="customer"
                    :exchange-bank="item"
                  />
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        text
                        @click="sendRegisterUpdatedToBank(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="far fa-envelope" />
                      </v-btn>
                    </template>
                    <span>Enviar Atualização ao Banco</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <!-- Add Exchange Bank Modal -->
    <v-dialog
      persistent
      scrollable
      eager
      min-width="40vw"
      max-width="100vh"
      v-model="addExchangeBankModal"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Adicionar banco</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-exchange-bank"
                ref="form"
              >
                <v-row>
                  <v-col md="12">
                    <v-autocomplete
                      label="Tipo de operação"
                      :items="filteredExchangeTypes"
                      item-text="description"
                      item-value="id"
                      v-model="exchangeBank.exchangeType"
                      return-object
                      clearable
                      dense
                      outlined
                      v-disabled-icon-focus
                    />
                  </v-col>
                  <v-col
                    md="12"
                    v-if="exchangeBank.exchangeType.id == 1"
                  >
                    <v-autocomplete
                      label="Banco"
                      :items="getRemittanceBanks"
                      item-text="fantasyName"
                      item-value="id"
                      v-model="exchangeBank.bank"
                      clearable
                      dense
                      outlined
                      v-disabled-icon-focus
                      return-object
                    />
                  </v-col>
                  <v-col
                    md="12"
                    v-else
                  >
                    <v-autocomplete
                      label="Banco"
                      :items="getTourismBanks"
                      item-text="fantasyName"
                      item-value="id"
                      v-model="exchangeBank.bank"
                      clearable
                      dense
                      outlined
                      v-disabled-icon-focus
                      return-object
                    />
                  </v-col>
                  <v-col md="12">
                    <v-autocomplete
                      label="Natureza da Operação"
                      :items="operationNatureList"
                      item-text="exibitionName"
                      item-value="id"
                      v-model="exchangeBank.operationNatures"
                      clearable
                      dense
                      outlined
                      multiple
                      v-disabled-icon-focus
                      return-object
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              v-if="!update"
              text
              class="btn-primary mr-2"
              @click="addExchangeBank"
            >
              Salvar
            </v-btn>
            <v-btn
              v-else-if="update"
              text
              class="btn-primary mr-2"
              @click="updateExchangeBank"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="addExchangeBankModal = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Limit Modal -->
    <v-dialog
      persistent
      scrollable
      eager
      min-width="40vw"
      max-width="100vh"
      v-model="addLimitModal"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Limite Aprovado</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-limit-modal"
                ref="form"
              >
                <v-row>
                  <template v-if="exchangeBank.useInOutLimit">
                    <v-col md="12">
                      <MoneyField
                        label="Valor Entrada"
                        :prefix="limitPrefix"
                        v-model="limit.valueIn"
                      />
                    </v-col>
                    <v-col md="12">
                      <MoneyField
                        label="Valor Saída"
                        :prefix="limitPrefix"
                        v-model="limit.valueOut"
                      />
                    </v-col>
                  </template>
                  <v-col
                    v-else
                    md="12"
                  >
                    <MoneyField
                      label="Valor"
                      :prefix="limitPrefix"
                      v-model="limit.value"
                    />
                  </v-col>
                  <v-col md="12">
                    <Datepicker
                      v-model="limit.expireAt"
                      label="Data de Validade"
                      v-disabled-icon-focus
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="addLimit"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="addLimitModal = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Send To Bank Modal -->
    <v-dialog
      persistent
      scrollable
      eager
      min-width="40vw"
      max-width="100vh"
      v-model="sendToBankModal"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Documentos</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              class="py-0"
              cols="12"
              md="12"
              v-for="(document) in customer.documents"
              :key="document.id"
            >
              <v-checkbox
                v-model="selectedDocuments"
                :label="document.documentType.description"
                :value="document.id"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>
          <h1 class="titulo-modal">Documentos Complementares</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              class="py-0"
              cols="12"
              md="12"
              v-for="(document) in customer.operationalDocuments"
              :key="document.id"
            >
              <v-checkbox
                v-model="selectedOperationalDocuments"
                :label="document.operationalDocumentType.description"
                :value="document.id"
              />
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              md="12"
            >
              <v-textarea
                auto-grow
                outlined
                rows="1"
                v-model="bankComplementaryInfo"
                label="Informações complementares"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="confirmationWindowCallback"
            >
              Enviar ao Banco
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="sendToBankModal = false"
            >
              Cancelar envio
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import apiExchangeBank from "@/api/exchangeBank/exchange-bank-api";
import apiOperationNature from "@/api/configuration/operation-nature-api";
import apiExchangeBankType from "@/api/exchangeBank/exchange-bank-type-api";
import apiCustomer from "@/api/customer/customer-api";
import apiTrigger from "@/api/generic/trigger-api";

// Components
import MoneyField from "@/components/comum/MoneyField";
import Datepicker from "@/components/comum/Datepicker.vue";
import CustomerBankStatement from "@/components/form-customer/CustomerBankStatement.vue";
import ConfirmationWindow from "@/components/comum/ConfirmationWindow";

// Models
import CustomerExchangeBankModel from "@/model/customer-exchange-bank-model";
import ExchangeBankModel from "@/model/exchange-bank-model";
import OperationNatureModel from "@/model/operation-nature-model";
import ExchangeBankTypeModel from "@/model/exchange-bank-type-model";
import CustomerLimitModel from "@/model/customer-limit-model";
import TriggerModel from "@/model/generic/trigger-model";

// Utils
import dateUtils from "../../../../common/utils/date";
import numberUtils from "../../../../common/utils/number";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Constants
import { SEND_EMAIL_TRIGGER_CODES } from "../../../../common/constants/generic/triggers";
import { CUSTOMER_WORKFLOW } from "../../../../common/workflows/customersWorkflow";

export default {
  name: "CustomerExchangeBanks",
  mixins: [mixinMessage],
  components: {
    MoneyField,
    Datepicker,
    CustomerBankStatement,
    ConfirmationWindow,
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    formatLimit(customerExchangeBank, value) {
      if (customerExchangeBank.bank.useUsdLimit) {
        return numberUtils.formatCurrency('USD', value);
      }

      return numberUtils.formatCurrency('BRL', value);
    },
  },
  data() {
    return {
      customer: this.customerProp,
      search: "",
      banks: [],
      operationNatureList: [],
      exchangeTypes: [],
      columns: [
        { text: "Tipo", value: "exchangeType.description", align: "left", width: "10%" },
        { text: "Banco Vinculado", value: "bank.fantasyName", align: "left", width: "10%" },
        { text: "Naturezas Aprovadas", value: "operationNatures", align: "left", width: "10%" },
        { text: "Limite Disponível Entrada", value: "limitIn", align: "center", width: "10%" },
        { text: "", value: "limit.remaining", align: "left", width: "5%" },
        { text: "Limite Disponível Saída", value: "limitOut", align: "center", width: "10%" },
        { text: "Validade do Limite", value: "limit.expireAt", align: "left", width: "10%" },
        { text: "", value: "actions", align: "right" },
      ],
      exchangeBank: new CustomerExchangeBankModel(),
      addExchangeBankModal: false,
      update: false,
      addLimitModal: false,
      limit: new CustomerLimitModel(),
      openConfirmationWindow: false,
      confirmationWindowCallback: () => {},
      confimationWindowMessage: '',
      sendToBankModal: false,
      selectedDocuments: [],
      selectedOperationalDocuments: [],
      currentBank: undefined,
      bankComplementaryInfo: '',
    };
  },
  mounted() {
    this.getBankList();
    this.getOperationNatureList();
    this.getExchangeTypes();
  },
  computed: {
    limitPrefix() {
      return this.exchangeBank.useUsdLimit ? 'USD ' : 'R$ ';
    },

    getRemittanceBanks() {
      if (this.banks.length == 0) {
        return [];
      }

      return this.banks.filter(b => b.exchangeType.id != 2);
    },

    getTourismBanks() {
      if (this.banks.length == 0) {
        return [];
      }

      return this.banks.filter(b => b.exchangeType.id != 1);
    },

    filteredExchangeTypes() {
      let filtered = this.exchangeTypes.filter(i => i.id != 3);

      return filtered;
    }
  },
  methods: {
    openAddExchangeBankModal() {
      this.update = false;
      this.exchangeBank = new CustomerExchangeBankModel();
      this.addExchangeBankModal = true;
    },

    editExchangeBankModal(exchangeBank) {
      this.update = true;
      this.exchangeBank = Object.assign({}, exchangeBank);
      this.addExchangeBankModal = true;
    },

    addExchangeBank() {
      this.customer.exchangeBanks.push(new CustomerExchangeBankModel(this.exchangeBank));
      this.addExchangeBankModal = false;
      this.confirmationWindowCallback = this.callbackSendBankRegister;
      this.sendToBankModal = true;
      this.updateStatusToSentToBank();
      this.$eventBus.$emit('customer-auto-save');
    },

    updateExchangeBank() {
      let index = this.customer.exchangeBanks.findIndex((b) =>
        (b.bank.id == this.exchangeBank.bank.id && b.exchangeType.id == this.exchangeBank.exchangeType.id)
      );

      this.customer.exchangeBanks.splice(
        index,
        1,
        new CustomerExchangeBankModel(this.exchangeBank)
      );
      this.addExchangeBankModal = false;

      this.$eventBus.$emit('customer-auto-save');
    },

    getBankList() {
      apiExchangeBank
        .findAll()
        .then((response) => {
          this.banks = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    getOperationNatureList() {
      apiOperationNature
        .findAll()
        .then((response) => {
          this.operationNatureList = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getExchangeTypes() {
      apiExchangeBankType
        .findAll()
        .then((response) => {
          this.exchangeTypes = response.data.map(t => new ExchangeBankTypeModel(t));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    openAddLimitModal(exchangeBank) {
      this.exchangeBank = Object.assign({}, exchangeBank.bank);

      this.limit = new CustomerLimitModel();
      let expire = new Date();
      expire.setFullYear(expire.getFullYear() + 1);
      this.limit.expireAt = expire.toISOString();

      this.addLimitModal = true;
    },

    addLimit() {
      if (this.exchangeBank.useInOutLimit) {
        this.limit.remainingIn = this.limit.valueIn;
        this.limit.remainingOut = this.limit.valueOut;
        this.limit.isInOut = true;
      } else {
        this.limit.remaining = this.limit.value;
      }

      apiCustomer
        .addLimit(this.customer.id, this.exchangeBank.id, this.limit)
        .then((response) => {
          let index = this.customer.exchangeBanks.findIndex((b) => (b.bank.id == this.exchangeBank.id));

          this.customer.exchangeBanks[index].limit = new CustomerLimitModel(response.data);
          this.addLimitModal = false;
          this.confirmationWindowCallback = this.callbackSendLimitAproval;
          this.confimationWindowMessage = 'Deseja enviar a aprovação de limite ao cliente?';
          this.openConfirmationWindow = true;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    callbackSendLimitAproval() {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.CLIENTE_APROVACAO_LIMITE,
        idCustomer: this.customer.id,
        idBank: this.exchangeBank.id,
      });

      apiTrigger
        .trigger(trigger)
        .then(() => {
          this.sendMessage("Aprovação de limite enviada com sucesso!", "success");
          this.openConfirmationWindow = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    callbackSendBankRegister() {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.BANCO_ENVIO_CADASTRO,
        idCustomer: this.customer.id,
        idBank: this.exchangeBank.bank.id,
        documentIds: this.selectedDocuments,
        customerOperationalDocumentIds: this.selectedOperationalDocuments,
        bankComplementaryInfo: this.bankComplementaryInfo,
      });

      apiTrigger
        .trigger(trigger)
        .then(() => {
          this.sendMessage("Ficha de cadastro enviada ao banco com sucesso!", "success");
          this.sendToBankModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    sendRegisterUpdatedToBank(exchangeBank){
      this.currentBank = exchangeBank;
      this.confirmationWindowCallback = this.callbackSendRegisterUpdatedToBankTrigger;
      this.sendToBankModal = true;
    },

    callbackSendRegisterUpdatedToBankTrigger() {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.BANCO_ATUALIZACAO_CADASTRAL,
        idCustomer: this.customer.id,
        idBank: this.currentBank.bank.id,
        documentIds: this.selectedDocuments,
        customerOperationalDocumentIds: this.selectedOperationalDocuments,
        bankComplementaryInfo: this.bankComplementaryInfo,
      });

      apiTrigger
        .trigger(trigger)
        .then(() => {
          this.sendMessage("Email de atualização cadastral enviado ao banco com sucesso!", "success");
          this.sendToBankModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    updateStatusToSentToBank() {
      apiCustomer
        .updateStatus(this.customer.id, CUSTOMER_WORKFLOW.ENVIADO_AO_BANCO)
        .then(() => { })
        .catch(() => { });
    },
  }
};
</script>

<style></style>
