import axios from 'axios';
const URL_VIACEP = process.env.VUE_APP_VIACEP;

function getAddressByCEP(cep) {
  return new Promise((resolve, reject) => {
    axios.get(`${URL_VIACEP}/${cep}/json/`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}


export default {
  getAddressByCEP,
};
