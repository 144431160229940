import { OPTIONS_STORE_CONFIGURATIONS } from "@/vuex/module/configurations";
import { CONFIGURATIONS_NAME } from "@/constants/configurations-constants";

const mixinConfigurationsPage = {
  data() {
    return {
      configurations: [],
    };
  },

  methods: {

    updateConfigurationList() {
      this.saveConfigurationsStore(this.configurations);
    },

    saveConfigurationsStore(configurations) {
      this.$store.dispatch(
        OPTIONS_STORE_CONFIGURATIONS.ACTIONS.UPDATE_CONFIGURATION_API, configurations);
    },

    getConfigurationsListForPage(page) {
      let configurations = this.$store.getters[OPTIONS_STORE_CONFIGURATIONS.GETTERS.GET_CONFIGURATIONS];
      let pageItems = CONFIGURATIONS_NAME.find(i => i.KEY == page).VALUE;

      this.configurations = configurations.filter(c => pageItems?.includes(c.name));
    },

  }

};

export default mixinConfigurationsPage;