import BilateralAgreementCountryModel from './bilateral-agreement-country-model';

export default class OperationNatureModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.name = obj.name;
    this.iof = (obj.iof && parseFloat(obj.iof)) || 0;
    this.ir = (obj.ir && parseFloat(obj.ir)) || 0;
    this.iofBuy = (obj.iofBuy && parseFloat(obj.iofBuy)) || 0;
    this.irBuy = (obj.irBuy && parseFloat(obj.irBuy)) || 0;
    this.useDiDue = obj.useDiDue;
    this.bilateralAgreementCountries = obj.bilateralAgreementCountries?.map(b => new BilateralAgreementCountryModel(b)) || [];
    this.code = obj.code;
    this.cide = obj.cide;
    this.cideBuy = obj.cideBuy;
    this.observation = obj.observation;
    this.exibitionName = this.code ? `${this.name} - ${this.code}` : this.name;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}