import api from '../api';

function add(formData) {
  formData = formData || {};

  return new Promise((resolve, reject) => {
    return api({
      method: 'post',
      url: '/payment-orders/documents',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
}

function findAll(paymentOrderId) {
  return new Promise((resolve, reject) => {
    return api.get(`/payment-orders/${paymentOrderId}/documents`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function remove(documentId) {
  documentId = documentId || {};

  return new Promise((resolve, reject) => {
    return api.delete(`/payment-orders/documents/${documentId}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function download(documentId){
  return  new Promise((resolve, reject) =>{
    return api.get('/payment-orders/documents/'+ documentId + '/download', { responseType: 'blob' })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  remove,
  add,
  download,
};
