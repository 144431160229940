
export default class OperationQueryModel {
  constructor(obj) {
    obj = obj || {};

    this.idCustomer = obj.idCustomer;
    this.idExchangeType = obj.idExchangeType;
    this.idBank = obj.idBank;
    this.idNature = obj.idNature;
    this.idCurrency = obj.idCurrency;
    this.amount = obj.amount;
    this.rate = obj.rate;
    this.contractCost = obj.contractCost;
    this.city = obj.city;
    this.idStore = obj.idStore;
    this.idDeliveryType = obj.idDeliveryType;
    this.deliveryCost = obj.deliveryCost;
    this.exchangeBuy = obj.exchangeBuy;
  }
}
