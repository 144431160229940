import CustomerDocumentModel from "../customer-document-model";
import CustomerOperationalDocumentModel from "../customer-operational-document-model";

export default class CustomerExternalAccessDocumentsModel {
  constructor(obj) {
    obj = obj || {};

    this.documents = obj.documents?.map(d => new CustomerDocumentModel(d));
    this.customerOperationalDocuments = obj.customerOperationalDocuments?.map(d => new CustomerOperationalDocumentModel(d));
  }
}