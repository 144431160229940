<template>
  <v-autocomplete
    :label="label"
    :items="listOfCnae"
    :item-text="(item) => item.id + ' - ' + item.descricao"
    item-value="id"
    v-model="cnaeData"
    clearable
    dense
    outlined
    v-disabled-icon-focus
    :rules="rules"
  />
</template>

<script>
// API
import apiIBGE from "@/api/general/ibge-api";

export default {
  name: "CNAEField",
  model: {
    prop: "model",
    event: "onChange",
  },
  watch: {
    cnaeData() {
      this.$emit("onChange", this.cnaeData);
    },
  },
  props: {
    model: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    rules: { type: Array, required: false },
  },
  data() {
    return {
      listOfCnae: undefined,
      cnaeData: this.model,
    };
  },
  mounted() {
    this.getUF();
  },
  methods: {
    getUF() {
      apiIBGE.getCnae().then((resposta) => {
        this.listOfCnae = resposta.data.map((cnae) => cnae);
      });
    },
  },
};
</script>

<style></style>
