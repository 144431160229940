import api from '../api';

function findCommercial(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/rates/commercial/', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findPtax(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/rates/ptax/', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findCommercial,
  findPtax,
};
