import api from '../api';

function getCustomerCount(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/dashboard/customers/count', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function getOperations(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/dashboard/operations', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function getCustomerExchangeBanksDistinctCustomer(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/dashboard/customers/exchange-banks/distinct-customer', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function getEmailCount(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/dashboard/email/count', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  getCustomerCount,
  getOperations,
  getCustomerExchangeBanksDistinctCustomer,
  getEmailCount,
};