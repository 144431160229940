import api from '../api';

function findAll() {
  return new Promise((resolve, reject) => {
    return api.get('/rate-apis')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function setActive(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.put(`/rate-apis/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  setActive,
};