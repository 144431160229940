export default class CustomerLimitModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.value = obj.value;
    this.valueIn = obj.valueIn;
    this.valueOut = obj.valueOut;
    this.remaining = obj.remaining;
    this.expireAt = obj.expireAt;
    this.remainingIn = obj.remainingIn;
    this.remainingOut = obj.remainingOut;
    this.isInOut = obj.isInOut;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}
