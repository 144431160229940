<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-show="loading">
      <v-col
        cols="12"
        lg="6"
      >
        <v-skeleton-loader
          class="mx-4"
          type="heading"
          min-width="100px"
        />
      </v-col>
      <v-col
        cols="12"
        lg="12"
      >
        <v-divider class="mx-4" />
        <v-skeleton-loader
          type="article"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="article"
          min-width="100px"
        />
        <v-skeleton-loader
          type="article"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-form
      ref="form"
      v-model="registrationValid"
      validation
    >
      <v-row v-show="!loading">
        <v-col
          cols="12"
          lg="12"
        >
          <h1 class="titulo"> {{ !modeEdication ? "Cadastro" : "Edição" }} de templates</h1>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          lg="3"
        >
          <v-text-field
            clearable
            v-model="template.description"
            autofocus
            label="Descrição"
            autocomplete="off"
            dense
            outlined
            :rules="requiredField"
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
          v-if="template.type?.id == TEMPLATE_TYPES.EMAIL.id"
        >
          <v-text-field
            clearable
            v-model="template.subject"
            autofocus
            label="Assunto"
            autocomplete="off"
            dense
            outlined
            :rules="requiredField"
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
        >
          <v-autocomplete
            clearable
            v-model="template.type"
            :items="templateTypes"
            label="Tipo"
            item-text="description"
            autocomplete="off"
            :rules="requiredField"
            dense
            return-object
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          lg="12"
        >
          <v-card class="card-cambio">
            <EditorTiny
              v-model="template.content"
              :key="componentKey"
              :altura-do-editor="800"
            />
          </v-card>
        </v-col>
        <v-row class="pa-4">
          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              class="btn-primary mx-2 "
              text
              :disabled="!registrationValid"
              @click="!modeEdication ? addTemplate() : updateTemplate()"
            >
              Salvar
            </v-btn>

            <v-btn
              class="btn-tertiary"
              text
              @click="cancel()"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
      <confirmation-window
        v-model="openConfirmationWindow"
        :callback-primary="callbackPrimary"
        :message="`Há dados preenchidos, deseja realmente abandonar ${modeEdication ? 'a edição?' : 'o cadastro?'}`"
        :text-primary-button="'Sim, abandonar'"
        :text-secondary-button="'Não'"
      />
    </v-form>
  </div>
</template>

<script>
// API
import apiTemplates from "@/api/generic/template-api";
import apiTemplateTypes from "@/api/generic/template-types-api";

// Models
import TemplateModel from "@/model/generic/template-model";
import TemplateTypeModel from "@/model/generic/template-model";

//Components
import EditorTiny from "@/components/comum/EditorTinymce";
import ConfirmationWindow from "@/components/comum/ConfirmationWindow";

// Constants
import { TEMPLATE_TYPES } from "@/constants/general-constants";

import mixpanel from "mixpanel-browser";
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "FormTemplate",
  components: {
    EditorTiny,
    ConfirmationWindow
  },
  mixins: [mixinMessage],
  data() {
    return {
      componentKey: 0,
      registrationValid: false,
      requiredField: [(v) => !!v || "Informação obrigatória"],
      loading: true,
      templates: [],
      template: new TemplateModel(),
      modeEdication: false,
      templateTypes: [],
      openConfirmationWindow: false,
      TEMPLATE_TYPES,
    };
  },

  mounted() {
    this.getTemplateTypes();
    mixpanel.track("page_view", { nome_pagina: "templates" });
  },

  methods: {
    callbackPrimary() {
      mixpanel.track("click", {
        nome_pagina: "cadastrar_templates",
        nome_botao: "btn_abandonar_cadastro_templates",
      });

      this.$router
        .push({
          name: "TemplatesControl",
        })
        .catch(() => { });
    },
    forceRerender() {
      this.componentKey += 1;
      this.checkMode();
    },
    checkMode() {
      let id = this.$route.params.id;

      if (id) {
        this.modeEdication = true;
        apiTemplates
          .findById(id)
          .then((resposta) => {
            this.template = new TemplateModel(resposta.data);
          })
          .catch(() => {
            this.sendMessage(
              "Não foi possível obter a template para edição.",
              "error"
            );
          });
      } else {
        this.modeEdication = false;
        this.template = new TemplateModel();
      }
    },

    updateTemplate() {
      mixpanel.track("click", {
        nome_pagina: "editar_templates",
        nome_botao: "btn_atualizar_template",
      });

      if (!this.template.type.id) {
        return;
      }

      this.template.typeId = this.template.type.id;

      apiTemplates
        .update(this.template)
        .then(() => {
          this.sendMessage("Template atualizada com sucesso", "success");

          this.$router
            .push({
              name: "TemplatesControl"
            })
            .catch(() => { });

        })
        .catch(() => {
          this.sendMessage("Falha ao atualizar o template", "error");
        });
    },

    cancel() {
      mixpanel.track("click", {
        nome_pagina: "cadastrar_templates",
        nome_botao: "btn_cancelar_templates",
      });

      if (
        this.template.description ||
        this.template.content ||
        this.template.typeId
      ) {
        this.openConfirmationWindow = true;
      } else {
        history.back();
      }
    },

    addTemplate() {
      mixpanel.track("click", {
        nome_pagina: "cadastrar_templates",
        nome_botao: "btn_cadastrar_templates",
      });

      if (!this.template.type.id) {
        return;
      }

      this.template.typeId = this.template.type.id;

      apiTemplates
        .add(this.template)
        .then(() => {
          this.sendMessage("Template cadastrada com sucesso.", "success");

          this.clearTemplate();

          this.$router
            .push({
              name: "TemplatesControl",
            })
            .catch(() => { });

        })
        .catch((error) => {
          this.sendMessage(error.data, "error");
        });


    },

    clearTemplate() {
      this.template = new TemplateModel();
    },

    getTemplateTypes() {
      apiTemplateTypes
        .findAll()
        .then((resposta) => {
          this.templateTypes = resposta.data.map(
            (t) => new TemplateTypeModel(t)
          );
          this.forceRerender();
          this.loading = false;
        })
        .catch(() => {
          /* Não fazer nada, é responsabilidade do sistema */
        });
    },
  },
};
</script>

<style lang="scss" scoped></style> 