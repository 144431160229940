export default class CustomerExchangeBankFiltersModel {
  constructor(obj) {
    obj = obj || {};

    this.startUpdateDate = obj.startUpdateDate;
    this.endUpdateDate = obj.endUpdateDate;
    this.customerRegisterStatusList = obj.customerRegisterStatusList;
    this.customerExchangeBankStatusList = obj.customerExchangeBankStatusList;
    this.idCustomer = obj.idCustomer;
    this.exchangeBankList = obj.exchangeBankList;
    this.idExchangeType = obj.idExchangeType;
  }
}