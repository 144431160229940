import api from '../api';

function add(spread, storeId) {
  spread = spread || {};

  return new Promise((resolve, reject) => {
    return api.post(`/exchange-stores/${storeId}/spreads`, spread)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(spread, storeId) {
  spread = spread || {};

  return new Promise((resolve, reject) => {
    return api.put(`/exchange-stores/${storeId}/spreads/${spread.id}`, spread)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findByStore(storeId) {
  return new Promise((resolve, reject) => {
    return api.get(`/exchange-stores/${storeId}/spreads`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(spreadId, storeId) {
  spreadId = spreadId || {};

  return new Promise((resolve, reject) => {
    return api.get(`/exchange-stores/${storeId}/spreads/${spreadId}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findByStore,
  findId,
  add,
  update,
};