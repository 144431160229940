import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export const OPTIONS_STORE_ACCESS = {
  ACTIONS: {
    FIND_ACCESS: 'access/findAccess'
  },
  GETTERS: {
    GET_ACCESS: 'access/getAccess'
  }
};

export default {
  state: {
    access: []
  },
  actions,
  getters,
  mutations,
  namespaced: true
};
