import OperationNatureModel from '../operation-nature-model';
import ExchangeBankModel from '../exchange-bank-model';
import ExchangeBankTypeModel from '../exchange-bank-type-model';
import CustomerLimitModel from '../customer-limit-model';
import Workflow from '../../../../common/models/Workflow';
import CustomerModel from '../customer-model';

export default class FilteredCustomerExchangeBankModel {
  constructor(obj) {
    obj = obj || {};

    let operationNatures = obj.operationNatures?.map(o => new OperationNatureModel(o));

    this.id = obj.id;
    this.customer = new CustomerModel(obj.customer);
    this.bank = new ExchangeBankModel(obj.bank);
    this.exchangeType = new ExchangeBankTypeModel(obj.exchangeType);
    this.operationNatures = operationNatures?.length ? operationNatures : [];
    this.limit = new CustomerLimitModel(obj.limit);
    this.status = new Workflow(obj.status);
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}