import api from '../api';

function add(order) {
  order = order || {};

  return new Promise((resolve, reject) => {
    return api.post('/payment-orders', order)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function find(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/payment-orders/', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(order) {
  order = order || {};

  return new Promise((resolve, reject) => {
    return api.put(`/payment-orders/${order.id}`, order)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.get(`/payment-orders/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  add,
  find,
  update,
  findId,
};
