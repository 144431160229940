import api from '../api';

function add(formData) {
  formData = formData || {};

  return new Promise((resolve, reject) => {
    return api({
      method: 'post',
      url: '/operation/documents',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
}

function findAll(operationId) {
  return new Promise((resolve, reject) => {
    return api.get(`/operation/${operationId}/documents`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function remove(documentId) {
  documentId = documentId || {};

  return new Promise((resolve, reject) => {
    return api.delete(`/operation/documents/${documentId}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function download(documentId){
  return  new Promise((resolve, reject) =>{
    return api.get('/operation/documents/'+ documentId + '/download', { responseType: 'blob' })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findExternalAccess() {
  return new Promise((resolve, reject) => {
    return api.get(`/operation/documents/external-access`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  remove,
  add,
  download,
  findExternalAccess,
};