import api from '../api';

function findAll(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/logs/email', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function resendEmail(id) {
  return new Promise((resolve, reject) => {
    return api.post(`/logs/email/resend/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  resendEmail,
};
